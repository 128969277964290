class ValidatorService {
  validate(fields, body) {
    return new Promise((resolve, reject) => {
      try {
        fields.forEach((element, index) => {
          if (
            !body[fields[index]] ||
            body[fields[index]] === "" ||
            body[fields[index]] === " "
          ) {
            let err_message;
            err_message = `${fields[index]} is required`;
            if (fields[index] == "your_password") {
              err_message = "Password is required";
            } else if (fields[index] == "full_name") {
              err_message = "Your name is required";
            } else if (fields[index] == "guardian_name") { // add
              err_message = "Parent/ Husband name is required";
            }  else if (fields[index] == "cnic") { // add
              err_message = "CNIC is required";
            } else if (fields[index] == "home_address") {
              err_message = "Current address is required";
            } else if (fields[index] == "permanent_address") {
              err_message = "Permanent address is required";
            } else if (fields[index] == "mailing_address") {
              err_message = "Mailing address is required";
            } else if (fields[index] == "academic_qualification") {
              err_message = "Academic qualification is required";
            // } else if (fields[index] == "professional_qualification") {
            //   err_message = "Professional qualification is required";
            } else if (fields[index] == "subject_id") {
              err_message = "Subject is required";
            } else if (fields[index] == "level_id") {
              err_message = "Level is required";
            } else if (fields[index] == "board_id") {
              err_message = "Board is required";
            } else if (fields[index] == "verify") {
              err_message = "Please check the verification box";
            }
            
            reject(err_message.charAt(0).toUpperCase() + err_message.slice(1));
          // } else if (
          //   fields[index] === "emailOrPhone" && !(
          //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          //     body[fields[index]]
          //   ) ||
          //   /^((\+92)?(0092)?(92)?(0)?)?3[0-9]{9}$/.test(body[fields[index]]) )
          // ) {
          //   reject(`Enter a valid email or phone`);
          }  else if (
            fields[index] === "email" &&
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              body[fields[index]]
            )
          ) {
            reject(`Enter a valid email`);
          } else if (
            (fields[index] === "phone" || fields[index] === "contact") && 
            !/^((\+92)?(0092)?(92)?(0)?)?3[0-9]{9}$/.test(body[fields[index]])
          )
           {
              reject(`Enter a valid contact Ex: 03******* || 92*******`);
          } else if (
            fields[index] === "password" &&
            (body[fields[index]].length < 8 || body[fields[index]].length > 20)
          ) {
            reject(`Enter a password in between 8 to 20 characters`);
          }
        });
        resolve(false);
      } catch (error) {
        reject(error.message);
      }
    });
  }

}
export default new ValidatorService();
