<template src="./mock-quiz-results.html"></template>

<script>
import appHeader from "./../../../shared-module/components/app-header/app-header.vue";

export default {
  name: "app-mock-quiz-results-test",
  data() {
    return {
      pageLoader: false,
      isPass: false,
      pageType: "",
    };
  },

  watch: {
  },
  components: {
    "app-header": appHeader,
  },
  created() {
  },
  mounted() {
  },
  methods:{}
};
</script>

<style src="./mock-quiz-results.css" scoped></style>
