<template src="./access-denied-popup.html"></template>

<script>
import customStorageService from "../../../app-services/custom-storage-service";
import pagination from "../../../shared-module/components/pagination/pagination";
import SingleQuestionStat from "../../../quiz-module/components/single-question-stat/single-question-stat";
import { isAndroid, isIOS } from "mobile-device-detect";

export default {
  name: "app-access-denied-pop-up",
  components: {
    "base-pagination": pagination,
    "app-single-question-stat": SingleQuestionStat
  },
  data() {
    return {
      user: {}
    };
  },
  props: {
    isMobileOrTablet: {
      type: Boolean
    }
  },
  mounted() {
    this.user = customStorageService.getUser();
  },
  methods: {
    DownloadApp() {
      if (this.isMobileOrTablet) {
        if (isIOS) {
          window.location.href = "https://www.nearpeer.org/np-link/ozpsqx5dmv";
        } else {
          window.location.href = "https://www.nearpeer.org/np-link/iowcp8ia8e";
        }
      } else {
          const protocolUrl = 'nearpeer-desktop-app://' + encodeURIComponent(`login?uid=${this.user?.id}&token=${this.user?.token}&redirect=/my-course`);
          // Try to open the app
          window.open(protocolUrl, '_self');

          setTimeout(() => {
            if(this.$store.state.isIframe){
              window.parent.postMessage({ action: `redirectTo=${encodeURIComponent('download-app')}` }, '*');
            }
            else window.location.href = "https://www.nearpeer.org/downloadapp";
          }, 1000); // Adjust the timeout duration as needed

        
      }
    },
    closeAccessPopup() {
      this.$router.go(-1);
    }
  }
};
</script>

<style src="./access-denied-popup.css" scoped></style>
