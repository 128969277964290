import { render, staticRenderFns } from "./practice-tour.html?vue&type=template&id=98f100a2&"
import script from "./practice-tour.vue?vue&type=script&lang=js&"
export * from "./practice-tour.vue?vue&type=script&lang=js&"
import style0 from "./practice-tour.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports