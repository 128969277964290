<template src="./desktop-app-header.html"></template>

<script>
export default {
  name: "desktop-app-header",
  props: {},
  directives: {},
  methods: {
    reloadApp() {
      window.location.reload();
    }
  },
  mounted() {}
};
</script>

<style src="./desktop-app-header.css" scoped></style>
