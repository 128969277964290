import { render, staticRenderFns } from "./desktop-app-header.html?vue&type=template&id=28cab752&scoped=true&"
import script from "./desktop-app-header.vue?vue&type=script&lang=js&"
export * from "./desktop-app-header.vue?vue&type=script&lang=js&"
import style0 from "./desktop-app-header.css?vue&type=style&index=0&id=28cab752&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28cab752",
  null
  
)

export default component.exports