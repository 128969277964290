<template src="./score-course-detail.html"></template>

<script>
import appHeader from "../../shared-module/components/app-header/app-header";
import appFooter from "../../shared-module/components/app-footer/app-footer.vue";
import appsidebar from "../../shared-module/components/app-sidebar/app-sidebar.vue";
import AppLogin from "../../shared-module/components/app-login/app-login";
import QuizService from "../../quiz-module/services/quiz-service";
import customStorageService from "../../app-services/custom-storage-service";
import { eventBus } from "../../../main";
import { lmsBackend } from "../../../environment/environment";
import VueApexCharts from "vue-apexcharts";
import html2canvas from "html2canvas";

export default {
  name: "score-predictor-list",
  data() {
    return {
      isLoading: false,
      user: customStorageService.getUser(),
      course_tag: this.$route.params.course_tag,
      module_tag: this.$route.params.module_tag,
      mainTagStats: {
        score_percentage: 0
      },
      shareDesc: `Hey! Look at my performance in ${this.$route.params.module_tag} topic at Nearpeer. You can join Nearpeer and get a detailed analysis of your strengths and weaknesses too.`,
      series: [70],
      strength: [],
      weakness: [],
      moreSwTagsStrength: [],
      moreSwTagsWeakness: [],
      isMoreTags: false,
      chartWeakness: {
        grid: {
          padding: {
            top: -15,
            left:-15,
            right:-15,
            bottom: -15
          }
        },
        chart: {
          height: 116,
          type: "radialBar"
        },
        colors: [""],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
              // margin:0,
            },
            track: {
              show: true,
              background: "#E3E8F1",
              strokeWidth: "100%",
              margin: 0 // margin is in pixels
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 0,
                show: false,
                color: "#333",
                fontSize: "12px",
                fontWeight: 400
              },
              value: {
                color: "#000",
                fontSize: "40px",
                fontWeight: 800,
                show: true,
                offsetY: 15
              }
            }
          }
        },
        fill: {
          type: "solid",
          colors: ["#e03249"]
        },
        stroke: {
          lineCap: "round"
        }
      },
      chartStrength: {
        grid: {
          padding: {
            top: -15,
            left:-15,
            right:-15,
            bottom: -15
          }
        },
        chart: {
          // height: 140,
          type: "radialBar"
        },
        colors: [""],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%"
            },
            track: {
              show: true,
              background: "#E3E8F1",
              strokeWidth: "100%",
              margin: 0 // margin is in pixels
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 0,
                show: false,
                color: "#333",
                fontSize: "12px",
                fontWeight: 400
              },
              value: {
                color: "#000",
                fontSize: "40px",
                fontWeight: 800,
                show: true,
                offsetY: 15
              }
            }
          }
        },
        fill: {
          type: "solid",
          colors: ["#22BC64"]
        },
        stroke: {
          lineCap: "round"
        }
      },
      linkToCopy: 'https://storage.googleapis.com/nps3storage/nearpeer-imagees/319254208647189-S_W_Stats.png',
      coppied: false,
    };
  },
  components: {
    "app-header": appHeader,
    "app-footer": appFooter,
    "app-sidebar": appsidebar,
    "app-login": AppLogin,
    apexchart: VueApexCharts,
  },
  mounted() {
    this.getUserStrengthWeakness();
    let user = customStorageService.getUser();

    if(!user) {
      console.log('11111')
      eventBus.$on("open-login-popup", this.handleLoginPopup);
    }
  },
  beforeDestroy() {
    // Remove event listener when the component is about to be destroyed
    eventBus.$off("open-login-popup", this.handleLoginPopup);
  },
  methods: {
    async getUserStrengthWeakness() {
      this.isLoading = true;
      const res = await QuizService.getUserSW({
        type: "level",
        tag_name: this.module_tag,
        course_tag_name: this.course_tag,
        user_id: this.$route.query.user_id ? this.$route.query.user_id : this.user.id,
      });
      this.isLoading = false;
      if(res.data && res.data.success) {
        this.mainTagStats = res.data.mainTagStats;
        res.data.swStats.forEach(stats => {
          if(stats.score_percentage >= 80) {
            this.strength.push(stats);
          } else {
            this.weakness.push(stats);
          }
        });
        let tagsToShow = 10;
        if(res.data.swStats.length > tagsToShow) {
          if(this.strength.length > tagsToShow) {
            let remaining = this.strength.slice(tagsToShow);
            this.strength = this.strength.slice(0, tagsToShow);
            this.moreSwTagsStrength = [...remaining, ...this.weakness];
            this.moreSwTagsWeakness = [...this.weakness];
          } else {
            let remaining = this.weakness.slice(tagsToShow - this.strength.length);
            this.weakness = this.weakness.slice(0, tagsToShow - this.strength.length);
            this.moreSwTagsWeakness = [...remaining];
          }
        }
      }
      console.log('page-height-' + document.documentElement.scrollHeight);
    },
    gotoPractice(type) {
      if(type === 'weakness') {
        this.$router.push(`/practices/new?type=weakness&topic=${this.module_tag}&course_tag=${this.course_tag}`);
      } else {
        this.$router.push(`/practices/new?type=topic&topic=${this.module_tag}&course_tag=${this.course_tag}`);
      }
    },
    shareProgress() {
      $('#sw_share').modal('show'); // This line opens the modal
      this.takeScreenshot();
    },
    copyURL(event) {
      event.preventDefault();
      const el = document.createElement("textarea");
      el.value = `${this.shareDesc}
      ${this.linkToCopy}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      navigator.clipboard.writeText(this.linkToCopy)
      this.coppied = true;
      setTimeout(() => {
        this.coppied = false;
      }, 3000);
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },
    async takeScreenshot() {
      let url = `${process.env.VUE_APP_BACKEND_LMS_BASE_URL}/user/score-preview/${this.course_tag}?preview=true&user_id=${this.user.id}&module_tag=${this.module_tag}&image_name=319254208647189-S_W_Stats.png`
          
      this.linkToCopy = url
      // const el = this.$refs.printMe;
      // const options = {
      //   type: "dataURL"
      // };
      // const printCanvas = await html2canvas(el, options);
      // var dataURL = printCanvas.toDataURL();
      // const file = this.dataURLtoFile(dataURL, "S_W_Stats.png");
      // let fd = new FormData();
      // fd.append("file", file, file.name);
      // return lmsBackend
      //   .post(
      //     "uploader/uploadImageToS3?userId=" + this.user.id,
      //     fd,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data"
      //       }
      //     }
      //   )
      //   .then(res => {
      //     const link = res.data.link;
      //     let lastSlashIndex = link.lastIndexOf('/');
      //     let extractedString = link.substring(lastSlashIndex + 1);
      //     let url = `https://isaps-devapigateway.nearpeer.org/lmsservice/v1/user/score-preview/1m5elyp?preview=true&image_name=${extractedString}`
          
      //     this.linkToCopy = url
      //   })
      //   .catch(err => {
      //     toastr.error("Oops! It seems service is down.");
      //     return err;
      //   });
    },
    handleLoginPopup() {
      console.log('232323232')
      this.$refs.loginModal.openSignupPopup();
    },
    onLoginew() {
      let user = customStorageService.getUser();
      if(user) {
        this.$refs.loginModal.closeLoginPage();
        location.reload();
      }
    },
  }
};
</script>

<style src="./score-course-detail.css" scoped></style>
