<template src="./mock-quiz-module.html"></template>

<script>
import customStorageService from "../../../app-services/custom-storage-service";
import appHeader from "./../../../shared-module/components/app-header/app-header.vue";
import steps from "./../../../shared-module/components/app-isap-steps/isap-steps.vue";

export default {
  name: "mock-quiz-module",
  directives: {},
  data() {
    return {
      step: 1,
      id_: this.$route.params.quiz_id,
      type:this.$route.params.type,
      modules: [],
      copyModules:[],
      mockExam: true,
      activeModule:{},
    };
  },

  watch: {},
  components: {
    "app-header": appHeader,
    "app-steps": steps,
  },
  mounted() {
    // copyModule()
    console.log("🚀 ~ data ~ id_:", this.id_);
    const data = customStorageService.getUserQuiz();
    this.modules = data.modules;
    this.copyModules = [
      { name: "Start", is_completed: true,progress:0 },
      ...data.modules,
      { name: "Complete", is_completed: false,progress:100 },
    ];
    console.log("----------modules------", this.copyModules);
    this.activeModule = this.modules.filter(module => module.is_active);
    console.log("Active Modules:", this.activeModule);
  },
  computed:
  {
    completedModulesCount() {
      return this.copyModules.filter(module => module.is_completed).length-2;
    }
  },
  methods: {
    startSection(){
      this.$router.push('/exam/'+ this.type+'/'+this.id_+ '/'+this.activeModule[0].id)
    },
    cancelExam(){
      $('#cancelExamFromButton').modal('show');
    },
    yes()
    {
      $('#cancelExamFromButton').modal('hide');
      this.$router.push(process.env.VUE_APP_COURSE_URL);
    },
  }
};
</script>

<style src="./mock-quiz-module.css" scoped></style>