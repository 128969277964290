import Vue from "vue";
import VueRouter from "vue-router";
import authService from "../app-services/auth-service";
import storageService from "../app-services/custom-storage-service";
import {
  AppPageNotFound,
  UnAuthorizedPage
} from "../shared-module/components/";
import { courseRoutes } from "../course-module/components";
import LiveChatInstructor from "./../app-live-chat-instructor/app-live-chat-instructor";
import LiveChatUnregistered from "./../app-live-chat-unregistered/app-live-chat-unregistered";
import LiveChat from "./../app-live-chat/app-live-chat";
import { examRoutes } from "../exam-module/components";
import { quizRoutes } from "../quiz-module/components";
import $ from "jquery";
// import AppWelcomePage from "./../app-welcome/app-welcome";
// import AppLoginPage from "./../app-login-page/login-page";
// import AppResetPage from "./../app-reset-password/reset-password";
// import AppScheduler from "./../app-schedule/schedule";
// import AppAssignments from "./../app-assignments/assignments";
import AppPractices from "./../app-practices/practice";
import AppPayment from "./../app-payment/payment";
import AppPracticesList from "./../app-practices/practice-list/practice-list";
import AppScorePredictor from "./../app-score-predictor/score-predictor";
import AppScorePredictorList from "./../app-score-predictor/app-score-chart-list/score-chart-list";
import AppScorePredictorDetail from "./../app-score-predictor/app-score-chart-detail/score-chart-detail";
import AppScorePredictorCourseDetail from "./../app-score-predictor/app-score-course-detail/score-course-detail";
import AppLivesession from "./../app-livesession/livesession";
import AppLivesessionDetail from "./../app-livesession-detail/livesession-detail";
import AppNotification from "./../app-notification/notification";
import AppSingleNotification from "./../app-notification-single/singleNotification";

import FaceRecognitionPage from "./../app-face-recognition/face-recognition-page";

Vue.use(VueRouter);
let isVideoJsLoaded = false;
async function guard(to, from, next) {
  let loggedIn = storageService.getUser();
  if (loggedIn) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login' route;
  }
}

function loadjscssfile(filename) {
  var fileref = document.createElement("script");
  fileref.setAttribute("type", "text/javascript");
  fileref.setAttribute("src", filename);
  if (typeof fileref != "undefined")
    document.getElementsByTagName("head")[0].appendChild(fileref);
}

const appRoutes = [
  {
    path: "",
    redirect: { name: "landing" }
  },
  {
    path: "/landing",
    name: "landing",
    component: () =>
      import(/* webpackChunkName: "course" */ "./../app-welcome/app-welcome"),
    meta: { title: "Landing-Page" }
  },
  {
    path: "/contact-us",
    name: "contact us",
    component: () =>
      import(/* webpackChunkName: "course" */ "./../app-contact-us/contact-us.vue"),
    meta: { title: "Contact Us" }
  },
  {
    path: "/closeWindow",
    name: "close",
    component: () =>
      import(/* webpackChunkName: "course" */ "./../close-window/close-window"),
    meta: { title: "close" }
  },
  {
    path: "/faceRecognition",
    name: "FaceRecognition",
    component: FaceRecognitionPage, // Use the imported component
    // component: () =>
    //   import(/* webpackChunkName: "course" */ "./../app-face-recognition/face-recognition-page"),
    meta: { title: "Face-Recognition-Page" }
  },
  {
    path: "/login",
    name: "AppLoginPage",
    component: () =>
      import(/* webpackChunkName: "course" */ "./../app-login-page/login-page"),
    meta: { title: "Login-Page" }
  },
  {
    path: "/sign-up",
    name: "AppSignUPPage",
    component: () =>
      import(/* webpackChunkName: "course" */ "./../app-signUp-page/signUp-page"),
    meta: { title: "Sign-up-Page" }
  },
  {
    path: "/forget-password",
    name: "AppForgetPassword",
    component: () =>
      import(/* webpackChunkName: "course" */ "./../app-forget-password/forget-password"),
    meta: { title: "Forget-Password" }
  },
  {
    path: "/resetPassword/:email/:token",
    name: "AppResetPage",
    component: () =>
      import(
        /* webpackChunkName: "course" */ "./../app-reset-password/reset-password"
      ),
    meta: { title: "App-Reset-Password-Page" }
  },
  {
    path: "/profile-complete",
    name: "AppProfileComplete",
    component: () =>
      import(
        /* webpackChunkName: "course" */ "./../app-complete-profile/complete-profile"
      ),
    meta: { title: "App-profile-complete" }
  },
  {
    path: "/identity-verification",
    name: "AppIdentityVerification",
    component: () =>
      import(
        /* webpackChunkName: "course" */ "./../app-identity-verification/identity-verification"
      ),
    meta: { title: "App-identity-verification" }
  },
  {
    path: "/result/exam/:result_id",
    name: "AppExamResult",
    component: () =>
      import(
        /* webpackChunkName: "course" */ "./../quiz-module/components/mock-quiz-results/mock-quiz-results.vue"
      ),
    meta: { title: "App-exam-result" },
  },
  {
    path: '/:type/exam-pre-permission',
    name: 'AppExam',
    component: () =>
      import(
        /* webpackChunkName: "course" */ "./../app-exam/exam"
    ),
    meta: { title: 'App-exam' },
  },
  {
    path: '/exam/:type',
    name: 'app-exam',
    beforeEnter: guard,
    component: () =>
      import(
        /* webpackChunkName: "course" */ "../exam-module/exam.vue"
    ),
    meta: { title: 'App-exam' },
  },
  // { 
  //   path: '/exam/:type/:quiz_id', 
  //   name: 'AppExamType',
  //   component: () =>
  //   import(
  //     /* webpackChunkName: "course" */ "./../quiz-module/components/mock-quiz-module/mock-quiz-module"
  //   ),
  // },
  // {
  //   path: '/exam/:type/:quiz_id/:module_id',
  //   name: 'AppExamModule',
  //   component: () =>
  //   import(
  //     /* webpackChunkName: "course" */ "./../quiz-module/components/mock-quiz-exam/mock-quiz-exam"
  //   ),
  // },
  {
    path: "/payment",
    name: "AppPayment",
    component: AppPayment,
    meta: { title: "Course-Payment" }
  },
  {
    path: "/confirmAccount/:email/:token",
    name: "AppConfirmPage",
    component: () =>
      import(
        /* webpackChunkName: "course" */ "./../app-reset-password/reset-password"
      ),
    meta: { title: "App-Confirm-Account-Page" }
  },
  {
    path: "/schedule",
    name: "AppSchedule",
    component: () =>
      import(/* webpackChunkName: "course" */ "./../app-schedule/schedule"),
    meta: { title: "Course-Schedule" }
  },
  {
    path: "/assignments",
    name: "AppAssignments",
    component: AppNotification,
    meta: { title: "Assignment-Notification" }
  },
  {
    path: "/live-chat/instructor/:session_id/:meta",
    name: "liveChatInstructor",
    component: LiveChatInstructor,
    meta: { title: "live-chat-instructor" }
  },
  {
    path: "/live-chat/unregistered/:session_id",
    name: "liveChatUnregistered",
    component: LiveChatUnregistered,
    meta: { title: "live-chat-unregistered" }
  },
  {
    path: "/live-chat/:session_id",
    name: "liveChat",
    component: LiveChat,
    meta: { title: "live-chat" }
  },
  {
    path: "/practices",
    name: "AppPractices",
    component: AppPractices,
    meta: { title: "Course-Practices" }
  },
  {
    path: "/live-session",
    name: "AppLivesession",
    component: AppLivesession,
    meta: { title: "Course-Livesession" }
  },
  {
    path: "/live-session/:session_id",
    name: "AppLivesessionDetail",
    component: AppLivesessionDetail,
    meta: { title: "Course-Livesession-detail" }
  },
  {
    path: "/practices/:state",
    name: "AppPracticesNew",
    component: AppPractices,
    meta: { title: "Course-Practices" }
  },
  {
    path: "/practices-list",
    name: "AppPracticesList",
    component: AppPracticesList,
    meta: { title: "Practices List" }
  },
  {
    path: "/score-predictor",
    name: "AppScorePredictor",
    component: AppScorePredictor
  },
  {
    path: "/strength-weakness",
    name: "AppScorePredictorList",
    component: AppScorePredictorList
  },
  {
    path: "/strength-weakness/:course_tag",
    name: "AppScorePredictorDetail",
    component: AppScorePredictorDetail
  },
  {
    path: "/strength-weakness/:course_tag/:module_tag",
    name: "AppScorePredictorCourseDetail",
    component: AppScorePredictorCourseDetail
  },
  {
    path: "/notifications",
    name: "AppNotification",
    component: AppNotification,
    meta: { title: "Course-Notification" }
  },
  {
    path: "/single-notification",
    name: "AppSingleNotification",
    component: AppSingleNotification,
    meta: { title: "Course-Single-Notification" }
  },
  {
    path: "/course",
    name: "app-course",
    beforeEnter: guard,
    component: () =>
      import(/* webpackChunkName: "course" */ "../course-module/course.vue")
  },
  {
    path: "/quiz",
    name: "app-quiz",
    beforeEnter: guard,
    component: () =>
      import(/* webpackChunkName: "course" */ "../quiz-module/quiz.vue")
  },
  {
    path: "/page-not-found",
    name: "page-not-found",
    component: AppPageNotFound
  },
  {
    path: "/unauthorized-page",
    name: "unauthorized-page",
    component: UnAuthorizedPage
  },
  {
    path: "*",
    name: "page-not-found",
    component: AppPageNotFound
  }
];
const routes = [...courseRoutes, ...examRoutes, ...quizRoutes, ...appRoutes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function getScript(arr, i) {
  i = i || 0;
  $.getScript(arr[i], function() {
    i++;
    arr.length > i && getScript(arr, i);
  });
}

function loadCSS(href) {
  var cssLink = $("<link>");
  $("head").append(cssLink); //IE hack: append before setting href

  cssLink.attr({
    rel: "stylesheet",
    type: "text/css",
    href: href
  });
}

router.beforeEach((to, from, next) => {
  let toPath = to;
  let loggedIn = storageService.getUser();
  const data=storageService.getExamStatus();
  if (data?.examPassed && loggedIn && toPath.path !== '/result/exam/'+data?.result_id) {   
    console.log("i am inside");
    // toPath = '/result/exam/'+data.result_id;

  next('/result/exam/'+data.result_id);return;
  }
  gtag("set", "page_path", toPath.path);
  gtag("event", "page_view");
  // $.getScript("https://www.googleoptimize.com/optimize.js?id=GTM-NHZH63N");
  // Logs for React Native APP
  if (
    !isVideoJsLoaded &&
    toPath.path !== "/landing" &&
    toPath.path !== "/login" &&
    toPath.path !== "/course/course-landing"
  ) {
    isVideoJsLoaded = true;
    loadCSS("https://vjs.zencdn.net/7.8.3/video-js.css");
    loadCSS("../../../../../../mathquill-0.10.1/mathquill.css");
    loadCSS(
      "https://cdnjs.cloudflare.com/ajax/libs/videojs-markers/0.7.0/videojs.markers.min.css"
    );
    loadCSS(
      "https://unpkg.com/videojs-max-quality-selector/dist/videojs-max-quality-selector.css"
    );
    loadCSS(
      "https://cdn.jsdelivr.net/npm/videojs-seek-buttons/dist/videojs-seek-buttons.css"
    );
    loadCSS(
      "https://cdn.jsdelivr.net/npm/videojs-hls-quality-selector@1.1.1/dist/videojs-hls-quality-selector.css"
    );

    $.getScript("../../../../../../mathquill-0.10.1/mathquill.js");
    getScript([
      "https://cdn.jsdelivr.net/npm/videojs-seek-buttons/dist/videojs-seek-buttons.min.js",
      "https://cdn.jsdelivr.net/npm/videojs-hls-quality-selector@1.1.1/dist/videojs-hls-quality-selector.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-quality-levels/2.0.8/videojs-contrib-quality-levels.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/videojs-markers/0.7.0/videojs-markers.min.js"
    ]);
  }
  if (toPath.path === "/landing" || toPath.path === "/course/course-landing") {
    console.log("initial-lms-screen");
  } else {
    console.log("initial-lms-screen-change");
  }
  const paramKeys = Object.keys(to.params);
  if (paramKeys && paramKeys.length > 0) {
    toPath = toPath.path.split("/");
    paramKeys.forEach(key => {
      toPath = toPath.map(path => {
        if (path == to.params[key]) {
          return ":" + key;
        }
        return path;
      });
    });
    toPath = toPath.join("/");
  } else {
    toPath = toPath.path;
  }
  next();
});

export default router;
