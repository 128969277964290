<template src="./mock-quiz-exam.html"></template>

<script>
import Vue from "vue";
import { eventBus } from "../../../../main";
import appHeader from "./../../../shared-module/components/app-header/app-header.vue";
import steps from "./../../../shared-module/components/app-isap-steps/isap-steps.vue";
import CustomStorageService from "../../../app-services/custom-storage-service";
import quizService from "../../services/quiz-service";
import pagination from "../../../shared-module/components/pagination/pagination";
import AppLogin from "../../../shared-module/components/app-login/app-login";
import appService from "../../../app-services/app-service";
import practiceLiveUsers from "../practice-users/practice-users.vue";
import {
  submitQuestionSocketInit,
  tracker
} from "../../services/submit-exam-service";
import { practiceSocketInit } from "../../services/practice-socket-service";
import VueEllipseProgress from "vue-ellipse-progress";
Vue.use(VueEllipseProgress);
import toastr from "toastr";
// import VueLightboxAdvanced from 'vue-lightbox-advanced';
// import 'vue-lightbox-advanced/dist/vue-lightbox-advanced.css';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
let practiceSocket;
let question;
let questions;
let currentIndex;
let matching_lines = [];
let matching_connectors = [];
let variables =[];

export default {
  name: "mock-quiz-exam",
  components: {
    "app-header": appHeader,
    "app-steps": steps,
    "base-pagination": pagination,
    "practice-live-users": practiceLiveUsers,
    "app-login": AppLogin,
    // VueLightboxAdvanced,
    CoolLightBox
  },
  directives: {
  },
  props: {
    uid: {
      type: String
    },
    sessionId: {
      type: String
    },
    quiz: {
      type: Object
    },
    user: {
      type: Object
    }
  },
  data() {
    return {
      compileResults: false,
      step: 1,
      marks:'',
      comment:'',
      module_id: this.$route.params.module_id,
      isPaperExam:false,
      steps: [
        {
          target: ".mark-btn",
          content:
            "If you are unsure about the answer of a question, you can mark It for review and recheck it later.",
          params: {
            placement: "bottom"
          }
        },
        {
          target: ".c-3",
          content:
            "Questions marked for review will be compiled in a list here. <br><br>You can use the arrow buttons to go to the previous or next marked question.",
          params: {
            placement: "bottom"
          }
        },
        {
          target: ".c-2",
          content:
            "All skipped questions will be compiled in a list here. <br><br>You can use the arrow buttons to go to the previous or next skipped question.",
          params: {
            placement: "bottom"
          }
        },
        {
          target: ".c-4",
          content:
            "Click the list icon to view a summary list of all the questions in the quiz.",
          params: {
            placement: "bottom"
          }
        }
      ],
      alphabets: ["A", "B", "C", "D", "E", "F", "G", "H"],
      skipKey: 0,
      timeCheck: true,
      quizSummary: false,
      interval: "",
      timeSpent: 0,
      timeConsumed: 0,
      storageId: "",
      attemptedQuestions: [],
      skippedQuestions: [],
      markedQuestions: [],
      attemptedPercentage: 0,
      isPageLoading: true,
      proWalkThrough: false,
      walkThrough: false,
      questions: [],
      question: {},
      index: 0,
      reportQuestionIndex: 0,
      loader: false,
      resultId: "",
      currentPage: 1,
      submitQuizBox: false,
      leaveQuiz: false,
      quizLoaderPercentage: 100,
      myCallbacks: {
        onFinish: this.finishWalkThrough,
        onSkip: this.finishWalkThrough,
        onPreviousStep: this.scrollTop
      },
      reportQuiz: false,
      reportQuizDescArr: [],
      reportQuizDesc: "",
      reportField: false,
      submitProgress: 0,
      loaderSize: 200,
      submitQuizBoxTest: false,
      liveUsers: [],
      practiceResult: false,
      rank: undefined,
      result_attempt_number: 0,
      timerPopupProceed: false,
      detectedUrdu: null,
      questionsMatch: [
        { id: 1, text: "Question 1" },
        { id: 2, text: "Question 2" },
        { id: 3, text: "Question 3" }
      ],
      answersMatch: [
        { id: 1, text: "Answer 1" },
        { id: 2, text: "Answer 2" },
        { id: 3, text: "Answer 3" }
      ],
      currentLine: null,
      lines: [],
      line: null,
      droppedText:[],
      answers:[],
      current_connector: null,
      connectors: [],
      indexBox: null
    };
  },

  watch: {
    question: function() {
      const ques = this.isPaperExam ? this.questions[this.index].student_attempt_id : this.questions[this.index].question_id;
      // if(this.index > 0){
      //   let aa = JSON.stringify(ques)
      //   this.question = JSON.parse(aa);
      // }
      // this.question = ques;
      question = this.question;
      questions = this.questions;
      currentIndex = this.index;
      
      if(this.question.type === 'mix_and_match'){
        this.connectors = [];
        this.current_connector = matching_connectors[currentIndex];
        this.question.options.forEach((opt, index) =>{
          this.connectors = [...this.connectors, {id: index * 2, type: 'start'}];
          this.connectors = [...this.connectors, {id: (index * 2) + 1, type: 'end'}];
        });
        
        this.$forceUpdate();
        this.$nextTick(()=>{
          this.init();
        });
      }
    },
    index: function(next, prev) {
      if(this.questions[prev].question_id && this.questions[prev].question_id.type === 'mix_and_match') {
        matching_connectors[prev] = document.getElementById('connectors').outerHTML;
        this.current_connector = matching_connectors[next];
        matching_lines[prev].forEach((line, index) => {
          line.hide();
        });
      }
    },
    currentPage: function() {
      if (this.index != this.currentPage - 1) {
        const skipBox = document.getElementById("selectSkipped");
        const markedbox = document.getElementById("selectMarked");
        if (skipBox && this.currentPage - 1 !== skipBox.value) {
          skipBox.value = -1;
        }
        if (markedbox && this.currentPage - 1 !== markedbox.value) {
          markedbox.value = -1;
        }
        this.goToQuestion(this.currentPage - 1);
      }
    },
  },
  
  created() {
    this.user = CustomStorageService.getUser();
    this.quiz_id = this.$route.params.quiz_id;
    console.log('Created worked ------------>', this.module_id, this.$route.params);
  },
  mounted() {
    console.log('mounted worked ------------>', this.module_id, this.$route.params);
    eventBus.$on("on-drag-end", this.handleDragEnd);
    this.checkUserVisitedFirstTime();
    this.uid = CustomStorageService.getUid();
    this.sessionId = this.$route.params.module_id;
    this.storageId = `Exam-${this.$route.params.module_id}-${CustomStorageService.getUid()}`;
    console.log('storage Id ---------->', this.storageId);
    this.updateLocalStorage(true);
    this.checkSkippedQuestions();
    this.checkMarkedQuestions();
    this.checkCompletionPercentage();
    this.loaderSize = window.innerWidth < 500 ? 130 : 200;
    this.timer();
    let current_module_id = this.$route.params.module_id;
    const exam = CustomStorageService.getUserQuiz();
    const current_module_index = exam.modules.findIndex(m => m.id.toString() === current_module_id.toString());
    if(current_module_index >= 0 && exam.modules[current_module_index].is_completed) {
      toastr.error("You've already attempted this module");
      this.$router.push(`/exam/${this.$route.params.type}/${exam._id}`);
    }
    if (!this.quiz) {
      // this.$router.push("/quiz/standard-quiz-main/" + this.sessionId);
    } else if (this.quiz.quiz_type === "practice") {
      practiceSocket = practiceSocketInit(
        {
          session_id: this.sessionId,
          quiz_id: this.quiz.id,
          user_id: this.uid
        },
        result => {
          this.usersListUpdated(result);
        },
        submit => {
          if (this.practiceResult && submit.submitBy !== this.user.id) {
            this.practiceResultPage();
          }
        },
        progress => {
          console.log('progress called');
        }
      );
    }
    this.setFocus();
  },
  
  methods:{
    setIndex(index) {
      this.indexBox = index
    },
    openLightbox() {
      this.indexBox = 0;
    },
    handleDragEnd(){
      if (this.question.type === 'mix_and_match' && currentIndex == this.index && question) {
        question.is_solved = true;
        let aa = JSON.stringify(question)
        // this.question = JSON.parse(aa);
        // this.question = question;
        questions[currentIndex] = question;
        this.questions[this.index].question_id = JSON.parse(aa);
        // this.questions[this.index].question_id = question;
      }
      this.updateLocalStorage();
      this.checkSkippedQuestions();
      this.checkCompletionPercentage();
      this.$forceUpdate();
    },
    init() {
      const variable = variables[currentIndex] ? variables[currentIndex] : {}; 
      const computedStyle = getComputedStyle(document.documentElement);
      const connectorsNode = document.getElementById("connectors");
      const connectors = connectorsNode.querySelectorAll(".connector.start");
      const lineWrapper = document.getElementById("line-wrapper");
      const plugs = document.getElementById("plugs");
      const plugPosition = plugs.getBoundingClientRect().y - plugs.previousElementSibling.getBoundingClientRect().height;
      const sockets = document.getElementById("sockets");
      const socketPosition = sockets.getBoundingClientRect().y - sockets.previousElementSibling.getBoundingClientRect().height;

      function remToPx(rem) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
      }

      function wrapperPosition(previous_value) {
        lineWrapper.style.transform = 'none';
        const rect = lineWrapper.getBoundingClientRect();
        lineWrapper.style.transform = previous_value ? previous_value : 'translate(' +
          (-(rect.left + pageXOffset)) + 'px, ' +
          (-(rect.top + pageYOffset)) + 'px)';

        variable.transform = previous_value ? previous_value : 'translate(' +
          (-(rect.left + pageXOffset)) + 'px, ' +
          (-(rect.top + pageYOffset)) + 'px)';
      }

      function areElementsOverlapping(element1, element2, radius) {
        // Calculate the center coordinates of element1
        var centerX1 = element1.left + (element1.width / 2);
        var centerY1 = element1.top + (element1.height / 2);

        // Calculate the center coordinates of element2
        // Assuming a default size for element2
        var centerX2 = element2.left + 0; // Assuming default width of 0
        var centerY2 = element2.top + 0; // Assuming default height of 0

        // Calculate the distance between the centers of the two elements
        var distance = Math.sqrt(Math.pow(centerX2 - centerX1, 2) + Math.pow(centerY2 - centerY1, 2));

        // Check if the distance is less than or equal to the sum of the radii
        return distance <= radius;
      }

      wrapperPosition(variable.transform);

      const ends = [];
      const snaptargets = [];

      
      Array.from(connectors).forEach(function (start, index) {
        const end = start.nextElementSibling;
        const plug = plugs.children[index];
        const p_position = plug.getBoundingClientRect();
        const p_midpoint = (p_position.y - plugPosition) + (p_position.height / 2);
        start.style.top = "calc(" + p_midpoint + "px - .5rem)";
        const socket = sockets.children[index];
        const s_position = socket.getBoundingClientRect();
        const s_midpoint = (s_position.y - socketPosition) + (s_position.height / 2);
        end.style.top = "calc(" + s_midpoint + "px - .5rem)";
        if(variables[currentIndex] && variables[currentIndex][index]){
          end.style.transform = variables[currentIndex][index].transform;
        }
        else end.style.transform = 'translate(0px)';

        const snaptarget = {
          x: (connectorsNode.offsetWidth - remToPx(.75)),
          y: s_midpoint
        };

        const div = document.createElement("div");
        div.classList.add("hint");
        div.style.top = snaptarget.y + 'px';
        div.style.left = 'calc(' + snaptarget.x + 'px - .125rem)';
        div.style.position = 'absolute';
        div.style.width = '.25rem';
        div.style.height = '.25rem';
        div.style.backgroundColor = '#a6dffc';
        div.style.borderRadius = '50px';
        div.style.zIndex = '1';
        div.dataset.index = index;
        connectorsNode.appendChild(div);
        snaptargets.push(snaptarget);

        const line = new LeaderLine(start, end, {
          startPlug: 'none',
          startPlugColor: '#a6dffc',
          endPlugColor: '#31b7f9',
          gradient: true,
          path: 'fluid',
          startSocket: 'right',
          endSocket: 'left'
        });
        if (!matching_lines[currentIndex]) matching_lines[currentIndex] = [];
        matching_lines[currentIndex][index] = line;

        const draggable = new PlainDraggable(end, {
          onMove: function () {
            wrapperPosition();
            line.position();
          },
          onMoveStart: function (event) {
            
            line.dash = { animation: true };
          },
          onDragEnd: (event) => {
            
            question.is_solved = true;
            let transf = document.getElementById('node_'+ parseInt(index * 2 + 1))
            variables[currentIndex] = variables[currentIndex] ? variables[currentIndex] : [];
            variables[currentIndex][index] = {transform: transf.style.transform, id: 'node_'+ parseInt(index * 2 + 1)}
            line.dash = false;

            const all_hints = connectorsNode.querySelectorAll(".hint");
            all_hints.forEach((hint, machting_index) => {
              if (areElementsOverlapping(hint.getBoundingClientRect(), event, 25)) {
                line.color = 'green';
                question.options[index].match_index = machting_index;
                questions[currentIndex] = question;

                eventBus.$emit("on-drag-end");
                
                return;
              }
              else {
                line.color = 'red';
                
              }
            });
          },
           
          autoScroll: true
        });
        ends.push(draggable);
      });
      ends.forEach(function (obj) {
        obj.snap = {
          targets: snaptargets,
          center: true,
          gravity: remToPx(2)
        };
      });
      variables[currentIndex] = variable;
    },
    removeLines(){
      matching_lines.forEach(ml => ml.forEach(line => line.remove()));
      matching_lines = [];
      matching_connectors = [];
      variables = [];
    },
    updateProgress(event){
      this.questions[this.index].student_attempt_id.marks = event.target.value;
      if(this.questions[this.index].student_attempt_id.marks){
        this.questions[this.index].student_attempt_id.is_solved = true;
      }
      else {
        this.questions[this.index].student_attempt_id.is_solved = false;
      }
      this.updateLocalStorage();
      this.checkSkippedQuestions();
      this.checkCompletionPercentage();
      this.$forceUpdate();
    },
    connect(index, event) {
          const questionItem = event.target;
          const answerItem = this.$el.querySelectorAll('.list')[1].children[index];

          const questionRect = questionItem.getBoundingClientRect();
          const answerRect = answerItem.getBoundingClientRect();

          this.line = new LeaderLine(
            questionItem,
            answerItem,
            { color: 'black', size: 2 }
          );

          this.lines.push({
            startX: questionRect.left + questionRect.width / 2,
            startY: questionRect.top + questionRect.height / 2,
            endX: answerRect.left + answerRect.width / 2,
            endY: answerRect.top + answerRect.height / 2
          });
    },
    startDragDrop(evt, indx) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('text', evt.target.textContent);
      // evt.dataTransfer.setData('index', indx);
    },
    onDrop(evt, selected) {
      evt.preventDefault();
      const data = evt.dataTransfer.getData('text');
      this.droppedText[this.index] = data
      this.question.is_solved = true;
      this.questions[this.index].question_id.is_solved = true;
      this.question.options.forEach((option, index) => {
        if (index === selected) {
          this.question.options[index].is_selected = true;
        } else {
          this.question.options[index].is_selected = false;
        }
        this.questions[this.index].question_id = this.question;
      });
      this.checkCompletionPercentage();
      this.updateLocalStorage();
      this.checkSkippedQuestions();
      this.$forceUpdate();
    },
    cancelDrop(index){
      this.droppedText[this.index] = ''
      this.questions[this.index].question_id.options[index].is_selected = false;

      this.updateLocalStorage();
      this.checkSkippedQuestions();
      this.checkCompletionPercentage();
      this.$forceUpdate();
    },
    addAnswer(index){
      this.question.is_solved = true;
      this.questions[this.index].question_id.is_solved = true;
      this.question.answer = this.answers[index];
      this.questions[this.index].question_id = this.question;
      this.updateLocalStorage();
      this.checkSkippedQuestions();
      this.checkCompletionPercentage();
      this.$forceUpdate();
    },
    startDrag(questionId) {
      const questionElement = document.querySelector(`[data-id="${questionId}"]`);
      const questionRect = questionElement.getBoundingClientRect();
      const x1 = questionRect.left + questionRect.width / 2;
      const y1 = questionRect.top + questionRect.height / 2;
      
      this.currentLine = {
        id: Date.now(), // Unique ID for the line
        x1: this.question.option -1 - questionId,
        y1: questionRect.y / (this.question.option - questionId),
        x2: x1, // Initial end position (will be updated during drag)
        y2: y1,
        questionId: questionId,
        answerId: null
      };

      window.addEventListener('mousemove', this.trackDrag);
    },
    trackDrag(event) {
      if (this.currentLine) {
        const rect = this.$refs.container.getBoundingClientRect();
        this.currentLine.x2 = rect.clientX;
        this.currentLine.y2 = rect.clientY;
      }
    },
    endDrag(answerId) {
      if (this.currentLine) {
        const answerElement = document.querySelector(`[data-id="${answerId}"]`);
        const answerRect = answerElement.getBoundingClientRect();
        const x2 = answerRect.left + answerRect.width / 2;
        const y2 = answerRect.top + answerRect.height / 2;
        
        this.currentLine.x2 = x2 + 100;
        this.currentLine.y2 = answerRect.y / 3;
        this.currentLine.answerId = answerId;
        
        this.lines.push(this.currentLine);
        this.currentLine = null;
        
        window.removeEventListener('mousemove', this.trackDrag);
      }
    },
    startSection(){
      this.examModule = false;
      this.mockExamQuiz = true;
    },
    usersListUpdated(result) {
      const users = result.userConnected.filter(
        user =>
        (user.status === "ROOM_JOINED" || user.status === "ROOM_CREATED") &&
        (this.uid !== user.id && this.randUserId !== user.id)
      );
      const quizSubmitedUsers = result.userConnected.filter(
        user => user.status === "QUIZ_SUBMITTED"
      );
      const activeUsers = result.userConnected.filter(
        user => user.status !== "DISCONNECTED"
      );
      
      if (quizSubmitedUsers.length === activeUsers.length) {
        this.practiceResultPage();
      }
      this.liveUsers = users;
    },
    async checkUserVisitedFirstTime() {
      if (this.user?.user_visits) {
        if (
          this.user?.user_visits?.visited_quiz_page == null ||
          !this.user.user_visits.visited_quiz_page
        ) {
          let obj = {
            uid: this.user.id,
            visited_quiz_page: true
          };
          try {
            if (this.user?.user_visits === null || !this.user?.user_visits) {
              this.user.user_visits = {
                visited_quiz_page: true
              };
            } else {
              this.user.user_visits.visited_quiz_page = true;
            }
          } catch (error) {
            console.log('catch called', error);
          }
          await appService.postUserVisited(obj);
        } else {
          this.finishWalkThrough();
        }
      }
    },
    nextSkipped() {
      const e = document.getElementById("selectSkipped");
      const to = Number(e.options[e.selectedIndex].nextSibling.value);
      this.skippedQuestions.forEach((q, i) => {
        if (q.index === to) {
          document.getElementById("selectSkipped").value = `${q.index}`;
          this.goToQuestion(q.index);
        }
      });
    },
    prevSkipped() {
      const e = document.getElementById("selectSkipped");
      const to = Number(e.options[e.selectedIndex].previousSibling.value);
      this.skippedQuestions.forEach((q, i) => {
        if (q.index === to) {
          document.getElementById("selectSkipped").value = `${q.index}`;
          this.goToQuestion(q.index);
        }
      });
    },
    nextMarked() {
      const e = document.getElementById("selectMarked");
      const to = Number(e.options[e.selectedIndex].text) - 1;
      this.markedQuestions.forEach((q, i) => {
        if (q.index === to) {
          document.getElementById("selectMarked").value = `${
            this.markedQuestions[i + 1].index
          }`;
          this.goToQuestion(q.index);
        }
      });
    },
    prevMarked() {
      const e = document.getElementById("selectMarked");
      const to = Number(e.options[e.selectedIndex].text) - 1;
      this.markedQuestions.forEach((q, i) => {
        if (q.index === to) {
          if (this.markedQuestions[i - 1]) {
            document.getElementById("selectMarked").value = `${
              this.markedQuestions[i - 1].index
            }`;
            this.goToQuestion(this.markedQuestions[i - 1].index);
          }
        }
      });
    },
    imgPlaceholder(e) {
      e.target.src = "../../../img/icons/Icon - Add Photo.svg";
    },
    startWalkThrough() {
      this.proWalkThrough = false;
      this.walkThrough = true;
      this.$tours["myTour"].start();
      this.scrollTop();
    },
    scrollTop() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0.5);
    },
    finishWalkThrough() {
      this.walkThrough = false;
      this.proWalkThrough = false;
      if (this.quiz) this.timer();
    },
    checkSkippedQuestions() {
      this.skippedQuestions = [];
      this.questions.forEach((question, index) => {
        if(!this.isPaperExam)
        {
        if (question.question_id.is_opened) {
          let skipped = true;
          if(question.question_id.type === 'fill_in_the_blank' && question.question_id.answer) { 
             skipped = false;
          } else {
            question.question_id.options.forEach((option, indx) => {
              if (option.is_selected || option.match_index || option.match_index == 0) {
                skipped = false;
                
              }
            });
          }
          if (skipped) {
            question.question_id.is_skipped = true;
            this.skippedQuestions.push({ index: index, question: question });
            
          } else {
            question.question_id.is_skipped = false;
          }
        } else {
          question.question_id.is_skipped = false;
        }
      }
      else{
        if (question.student_attempt_id.is_opened) {
                let skipped = true;
                if(question.student_attempt_id.marks!=='') { 
                   skipped = false;
                }
                else
                {            
                  skipped = false;
                }
                if (skipped) {
                  question.student_attempt_id.is_skipped = true;
                  this.skippedQuestions.push({ index: index, question: question });
                } else {
                  question.student_attempt_id.is_skipped = false;
                }
              } else {
                question.student_attempt_id.is_skipped = false;
              }
        }
      });
      this.skippedQuestions.sort((a, b) => a.index - b.index);
      if (this.index !== 0) {
        this.updateLocalStorage();
      }
    },
    markTrue(selected) {
      this.question.is_solved = true;
      this.questions[this.index].question_id.is_solved = true;
      this.question.options.forEach((option, index) => {
        if (index === selected) {
          this.question.options[index].is_selected = true;
        } else {
          this.question.options[index].is_selected = false;
        }
        this.questions[this.index].question_id = this.question;
      });
      this.updateLocalStorage();
      this.checkSkippedQuestions();
      
      this.checkCompletionPercentage();
      this.$forceUpdate();
    },
    checkCompletionPercentage() {
      this.attemptedQuestions = [];
      // if (this.question.type === 'mix_and_match' && currentIndex == this.index && question) {
      //   let aa = JSON.stringify(question)
      //   // this.question = JSON.parse(aa);
      //   // this.question = question;
      //   questions[currentIndex] = question;
      //   this.questions[this.index].question_id = JSON.parse(aa);
      // }

      
      this.questions.forEach((question, index) => {
       if(!this.isPaperExam)
       { 
        
        if (question.question_id.is_solved) {
          
          this.attemptedQuestions.push({ index: index, question: question });
        } else {
          question.question_id.is_solved = false;
        }
       }
       else{
        
        if (question.student_attempt_id.is_solved) {
          
          this.attemptedQuestions.push({ index: index, question: question });
        } else {
          question.student_attempt_id.is_solved = false;
        }
       } 
      });
      // if (this.attemptedQuestions.length) {
      //   const found = this.attemptedQuestions.find(
      //     (element) => element._id === this.question._id
      //   );
      //   if (!found) {
      //     this.attemptedQuestions.push(this.questions[this.index]);
      //   }
      // } else {
      //   this.attemptedQuestions.push(this.questions[this.index]);
      // }
      
      this.attemptedPercentage =
        (this.attemptedQuestions.length / this.questions.length) * 100;
      this.attemptedPercentage = parseInt(this.attemptedPercentage, 10);
      if (document.getElementById("progress-bar")) {
        document.getElementById("progress-bar").style.width =
          this.attemptedPercentage + "%";
      }
      this.updateLocalStorage();
    },
    handlePageProgress(){
        if(this.questions[this.index].student_attempt_id.marks!=='')
        {
          this.questions[this.index].student_attempt_id.is_solved=true;
          this.questions[this.index].student_attempt_id.is_marked=true;
        }
        else{
          this.questions[this.index].student_attempt_id.is_skipped=true;
        }
      this.checkSkippedQuestions();
      this.checkMarkedQuestions();
      this.updateLocalStorage();
      this.$forceUpdate();
    },
    goToQuestion(to, type) {
      if (to === "" || to === -1 || to === "-1") return;
      if(this.isPaperExam)
      {
        this.handlePageProgress();
      }
      this.quizSummary = false;
      this.index = to;
      if (type === "next") {
        this.$refs.paginationss.nextPage();
      } else if (type === "prev") {
        this.$refs.paginationss.prevPage();
      } else {
        this.$refs.paginationss.changePage(to + 1);
      }
      if(!this.isPaperExam)
      {
        this.question = this.questions[to].question_id;
        this.question.is_opened = true;
        document.getElementById("test").scrollIntoView({ behavior: "smooth" });
      }
      else{
        this.question = this.questions[to].student_attempt_id;
        this.question.is_opened = true;
        document.getElementById("test1").scrollIntoView({ behavior: "smooth" });
      }
      this.checkSkippedQuestions();
      this.checkMarkedQuestions();
      this.updateLocalStorage();
      this.$forceUpdate();
      if(this.question.type === 'fill_in_the_blank'){
        this.setFocus();
      }
    },
    markQuestion() {
      this.question.question_id.is_marked = !this.question.question_id.is_marked;
      this.checkMarkedQuestions();
    },
    checkMarkedQuestions() {
      this.markedQuestions = [];
      this.questions.forEach((question, index) => {
      if(!this.isPaperExam)
        {
          if (question.question_id.is_marked) {
            this.markedQuestions.push({ index: index, question: question });
          } else {
            question.question_id.is_marked = false;
          }
        }
      else{
          if (question.student_attempt_id.is_marked) {
            this.markedQuestions.push({ index: index, question: question });
          } else {
            question.student_attempt_id.is_marked = false;
          }
        }
      });
      this.updateLocalStorage();
    },
    reportProblem() {
      
      this.reportQuiz = true;
      this.reportQuizDescArr = [];
      this.reportQuizDesc = "";
    },
    cancelReport() {
      
      this.reportQuiz = false;
      this.reportField = false;
      this.reportQuizDescArr = [];
      this.reportQuizDesc = "";
    },
    cancelReportQuiz() {
      

      this.reportQuiz = false;
    },
    async postReportQuiz() {
      if (
        this.reportQuizDesc.length == 0 &&
        this.reportQuizDescArr.length == 0
      ) {
        return toastr.info("Please Specify The Problem.");
      }
      
      this.reportQuiz = false;
      this.reportField = false;
      if (this.reportQuizDesc.length > 0) {
        this.reportQuizDescArr.push(this.reportQuizDesc);
      }
      let reportDesc = {
        report_des_arr: this.reportQuizDescArr
      };
      let reportDescAr;
      reportDescAr = this.reportQuizDescArr;

      let obj = {
        report_type: "quiz",
        quiz_id: this.quiz.id,
        user_id: this.user.id,
        report_desc: reportDesc
      };
      this.reportQuestionIndex = this.index + 1;

      let obj2 = {
        report_type: "quiz",
        quiz_id: this.quiz.id,
        user_id: this.user.id,
        report_desc: reportDescAr,
        question_no: this.reportQuestionIndex,
        question_id: this.question._id
      };
      let [reportSent, reportSent2] = await Promise.all([
        // quizService.createReport(obj),
        quizService.createReportInQuizBackend(obj2)
      ]);
      if (reportSent.data.status == "success") {
        toastr.info("Successfully reported");
        this.reportQuizDescArr = [];
        this.reportQuizDesc = "";
      } else {
        toastr.error("Something went wrong!");
      }
    },
    isHTML(str) {
      var a = document.createElement("div");
      a.innerHTML = str;
      for (var c = a.childNodes, i = c.length; i--; ) {
        if (c[i].nodeType == 1) return true;
      }
      return false;
    },
    span(text) {
      return `<span> ${text} </span>`;
    },
    findQuiz(){
      const data=CustomStorageService.getUserQuiz();
      const filteredData = data.modules.filter(item => item.id === parseInt(this.module_id));
      if(filteredData)
      {
        console.log('exam module data found', filteredData);
        this.quiz=filteredData;
        this.isPaperExam=filteredData[0].is_paper_evaluation;
        return filteredData[0];
      }
    },
    hasCircularReference(obj, seen = new WeakSet()) {
      if (typeof obj !== 'object' || obj === null) {
        return false;
      }

      if (seen.has(obj)) {
        return true;
      }

      seen.add(obj);

      for (const key in obj) {
        if (this.hasCircularReference(obj[key], seen)) {
          return true;
        }
      }

      return false;
    },
    updateLocalStorage(first) {
      if (first) {
        // check if(any other quiz data existed in localstorage and remove if any)
        const LocalStorageKeys = Object.keys(localStorage);
        for (let i = 0; LocalStorageKeys.length > i; i += 1) {
          if (
            LocalStorageKeys[i].startsWith("Exam") &&
            LocalStorageKeys[i] !== `Exam-${parseInt(this.module_id)}-${this.uid}`
          ) {
            localStorage.removeItem(LocalStorageKeys[i]);
          }
        }
        const module = this.findQuiz();
        if(!module.questions.length) {
          toastr.info('This module has no question');
          let current_module_id = this.$route.params.module_id;
          const exam = CustomStorageService.getUserQuiz();
          const current_module_index = exam.modules.findIndex(m => m.id.toString() === current_module_id.toString());
          if(current_module_index >= 0 && current_module_index < exam.modules.length - 1) {
            exam.modules[current_module_index].is_completed = true;
            exam.modules[current_module_index].is_active = false;
            exam.modules[current_module_index + 1].is_active = true;
            CustomStorageService.setUserQuiz(exam);
          }
          else {
            exam.modules[current_module_index].is_completed = true;
            exam.modules[current_module_index].is_active = false;
            CustomStorageService.setUserQuiz(exam);
          }
          // this.submitQuiz();
          this.$forceUpdate();
          this.$router.push(`/exam/${this.$route.params.type}/${exam._id}`);
        }
        if (this.quiz && !this.isPaperExam) {
          this.questions = module.questions;
          let aa = JSON.stringify(module.questions[0].question_id)
          this.question = JSON.parse(aa);
          // this.question = module.questions[0].question_id;
          this.question.is_opened = true;
        }
        else{
          this.questions = module.questions;
          console.log('module.questions', module.questions);
          let aa = JSON.stringify(module.questions[0].student_attempt_id)
          this.question = JSON.parse(aa);
          // this.question = module.questions[0].student_attempt_id;
          this.question.is_opened = true;
        }
      }
      // this.storageId = `Exam-${this.module_id}-${this.uid}`;
      if (this.questions && this.questions.length) {
        // if (this.question.type === 'mix_and_match' && currentIndex == this.index && question) {
        //   let aa = JSON.stringify(question)
        //   // this.question = JSON.parse(aa);
        //   // this.question = question;
        //   questions[currentIndex] = question;
        //   this.questions[this.index].question_id = JSON.parse(aa);
        // }
        
        // this.user = CustomStorageService.getUser();
        // this.findQuiz();
        const data = {
          user: this.user,
          quiz: this.quiz,
          questions: this.questions,
          index: this.index,
          timeConsumed: this.timeConsumed
        };
        console.log('set data storage Id ---------->', this.storageId, data);
        CustomStorageService.set(this.storageId, data);
      } else {
        let data = CustomStorageService.get(this.storageId);
        console.log('get data storage Id ---------->', this.storageId, data);
        if (data && !this.isPaperExam) {
          this.index = data.index;
          this.currentPage = data.index + 1;
          this.quiz = data.quiz;
          this.user = data.user;
          this.questions = data.questions;
          this.timeConsumed = data.timeConsumed;
          this.timeSpent = data.timeConsumed;
          let aa = JSON.stringify(this.questions[0].question_id)
          this.question = JSON.parse(aa);
          // this.question = this.questions[0].question_id;
          this.question.question_id.is_opened = true;
        }
        else{
          this.index = data.index;
          this.currentPage = data.index + 1;
          this.quiz = data.quiz;
          this.user = data.user;
          this.questions = data.questions;
          this.timeConsumed = data.timeConsumed;
          this.timeSpent = data.timeConsumed;
          this.question = this.questions[0].student_attempt_id;
          this.question.student_attempt_id.is_opened = true;
          this.question.type='paper';
          this.$forceUpdate();
        }
      }
      this.isPageLoading = false;
    },
    mathCreator: function(question, id, data) {
      setTimeout(function() {
        let mathFieldSpan = document.getElementById(id);
        let MQ = MathQuill.getInterface(2);
        let mathField = MQ.StaticMath(mathFieldSpan);
        if (!data.match(/\\\s/g)) {
          data = data.replace(/\s+/g, "\\ ");
        }
        if (data !== "" && data !== "null") {
          mathField.latex(data);
        }
      }, 500);
    },
    timer() {
      const duration = this.quiz[0].duration * 60;
      if (duration > 0) {
        const seconds = duration - this.timeConsumed;
        let timeInMinutes = seconds / 60;
        this.startTimer(seconds);
      }
    },
    startTimer(sec) {
      let timer = sec,
        minutes,
        seconds;
    // if(!this.isPaperExam){  
      this.interval = setInterval(() => {       
        //update question time
        if (!this.question.time_spent) {
          this.question.time_spent = 1;
        } else {
          this.question.time_spent += 1;
        }

        //update quiz time
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        document.querySelector("#timer").textContent = this.formatTime(
          timer * 1000
        );
        this.timeSpent += 1;
        if (--timer < 0 && !this.timerPopupProceed) {
          timer = 0;
          this.timeCheck = false;
          this.timeUpBox = true;
          // this.$forceUpdate();
          clearInterval(this.interval);
        }
        this.timeConsumed = this.timeConsumed + 1;
        // this.checkSkippedQuestions();
        // this.updateLocalStorage();
        this.$forceUpdate();
      }, 1000);
      // }
    },
    mainlogoClick() {
      mixpanel.track('VL-Standard-Quiz-Leave',{
        'Course_Id' : this.quiz.course_id,
        'Instructor_Id' : this.quiz.instructor_id,
        'Niche_Name' : this.quiz.course_tag,
        'Quiz_Type' : this.quiz.quiz_type,
        "Quiz_Name" : this.quiz.name,
        "Quiz_ID" : this.quiz.pg_quiz_id,
      });
      if (this.$store.state.isNewReactNative) {
        
        console.log('main logo called');
      } else {
        this.$router.push("/course/course-landing");
      }
    },
    async submitQuiz() {
      this.submitQuizBox = false;
      this.submitQuizBoxTest = false;
      this.timeUpBox = false;
      const browser_id = CustomStorageService.getBrowserId();
      
      const exam = CustomStorageService.getUserQuiz();
      
      if(exam && exam.modules[exam.modules.length - 1].id.toString() === this.$route.params.module_id.toString()) {
        
        this.compileResults = true;
      }
      this.timerPopupProceed = true;
      const user = CustomStorageService.getUser();
      if(user && user.id) {
        this.user = CustomStorageService.getUser();
        this.$refs.loginModal.closeLoginPage();
        try {
          this.submitProgress = 0;
          this.skippedQuestions = [];
          let attemptedQuestions = [];
          this.questions.forEach((question, index) => {
            if(this.isPaperExam) {
              if(question.student_attempt_id.marks === 0 || question.student_attempt_id.marks || question.student_attempt_id.comment) { 
                attemptedQuestions.push(index);
              }
            } else {
              if(question.question_id.type === 'fill_in_the_blank' && question.question_id.answer) { 
                attemptedQuestions.push(index);
              } else {
                question.question_id.options.forEach((option, indexOption) => {
                  if (option.is_selected || option.match_index || option.match_index == 0) {
                    attemptedQuestions.push(index);
                  }
                });
              }
            }
            if (!attemptedQuestions.includes(index)) {
              this.skippedQuestions.push({ index, question });
            }
          });
          const body = {
            time_spent: Number(this.timeSpent),
            skipped_questions: this.skippedQuestions,
            marked_questions: this.markedQuestions,
            user: this.user,
            quiz: this.quiz,
            exam,
            questions: this.questions,
            module_id: this.$route.params.module_id,
            prediction: sessionStorage.getItem(`prediction-${this.quiz._id}`),
            compileResults: this.compileResults,
            browser_id
          };
          this.loader = true;
          clearInterval(this.interval);
          const user_details = await tracker();
          this.submitProgress = 20;
          if(typeof this.uid === 'string' && this.uid.includes('__') && practiceSocket) {
            practiceSocket.emit("disconnect-user", {
              session_id: this.sessionId,
              quiz_id: this.quiz._id,
              user_id: this.uid
            });
          }
          submitQuestionSocketInit(
            body,
            user_details,
            result => {
              let rank = result.rank;
              this.rank = result.rank;
              result = result.result;
              this.loader = false;
              this.resultId = result._id;
              toastr.success("Exam Submitted");
              sessionStorage.removeItem(`prediction-${this.quiz._id}`);
              if (this.quiz.quiz_type === "practice") {
                this.result_attempt_number = result.attempt_number;
                this.submitPracticeQuiz();
              } else {
                
                this.$router.push(`/result/exam/${this.resultId}`);
                // if (result.attempt_number == 1 && rank && rank > -1 && rank <= 50)
                //   this.$router.push("/quiz/result-position/" + this.resultId);
                // else
                //   this.$router.push(
                //     "/quiz/standard-quiz-results/" + this.resultId
                //   );
              }
            },
            error => {
              // throw new Error(error);result.rank
              toastr.error(error);
              this.loader = false;
              this.submitProgress = 0;
              this.submitQuizBoxTest = true;
            },
            progress => (this.submitProgress = 20 + progress),
            loader => {
              let current_module_id = this.$route.params.module_id;
              const current_module_index = exam.modules.findIndex(m => m.id.toString() === current_module_id.toString());
              if(current_module_index >= 0) {
                exam.modules[current_module_index].is_completed = true;
                exam.modules[current_module_index].is_active = false;
                exam.modules[current_module_index + 1].is_active = true;
                CustomStorageService.setUserQuiz(exam);
              }
              this.loader = loader;
              this.$forceUpdate();
              this.$router.push(`/exam/${this.$route.params.type}/${exam._id}`);
            },
          );
          
          return false;
        } catch (error) {
          this.loader = false;
          this.submitProgress = 0;
          this.submitQuizBoxTest = true;
          if (error.response) {
            toastr.error(error.response.data.errors[0].messages[0]);
          } else if (error.message) {
            toastr.error(error.message);
          } else {
            toastr.error(error);
          }
        }
      } else {
        this.$forceUpdate();
        this.$refs.loginModal.openSignupPopup();
      }
      
    },
    submitPracticeQuiz() {
      practiceSocket.emit("quiz-submitted", {
        session_id: this.sessionId,
        quiz_id: this.quiz._id,
        user_id: this.user.id,
        result_id: this.resultId
      });
      if (this.liveUsers && this.liveUsers.length > 0) {
        this.practiceResult = true;
      } else {
        if (
          this.result_attempt_number == 1 &&
          this.rank &&
          this.rank > -1 &&
          this.rank <= 50
        )
          this.$router.push("/quiz/result-position/" + this.resultId);
        else this.$router.push("/quiz/standard-quiz-results/" + this.resultId);

      }
    },
    practiceResultPage() {
      if(this.resultId) {
        if (
          this.result_attempt_number == 1 &&
          this.rank &&
          this.rank > -1 &&
          this.rank <= 50
        )
          this.$router.push("/quiz/result-position/" + this.resultId);
        else this.$router.push("/quiz/standard-quiz-results/" + this.resultId);
      }
      
    },
    formatTime(msec) {
      let avgTime = "";
      let time = new Date(msec).toISOString().substr(11, 8);
      time = time.split(":");
      if (parseInt(time[0]) > 0) {
        avgTime = time[0] + "h ";
      }
      if (parseInt(time[1]) > 0) {
        avgTime = avgTime + time[1] + "m ";
      }
      if (parseInt(time[2]) > 0) {
        avgTime = avgTime + time[2] + "s";
      }
      return avgTime;
    },
    showSubmitBox() {
      mixpanel.track('VL-Quiz-Finsh',{
        "Quiz_Name" : this.quiz.name,
        "Quiz_ID" : this.quiz.pg_quiz_id
      });
      const attemptedQuestions = [];
      this.skippedQuestions = [];
      this.questions.forEach((question, index) => {
        if(!this.isPaperExam)
        {
        if(question.question_id.type === 'fill_in_the_blank' && question.question_id.answer) { 
          attemptedQuestions.push(index);
        } 
        else {
          question.question_id.options.forEach((option, indexOption) => {
            if (option.is_selected || option.match_index || option.match_index == 0) {
              attemptedQuestions.push(index);
            }
          });
        }
        }
        else{
        if(question.student_attempt_id.marks) { 
          attemptedQuestions.push(index);
          } 
        }
        if (!attemptedQuestions.includes(index)) {
          this.skippedQuestions.push({ index, question });
        }
      });
      
      this.submitQuizBox = true;
      
    },
    setFocus() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    if(practiceSocket) {
      practiceSocket.emit("disconnect-user", {
        session_id: this.sessionId,
        quiz_id: this.quiz.id,
        user_id: this.uid
      });
    }
    
    this.removeLines();
    next();
  },
  beforeDestroy() {
    eventBus.$off("on-drag-end", this.handleDragEnd);
    this.removeLines();
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.trackDrag);
  },
  destroyed() {
    
    console.log('destroyed called');
  },
};
</script>

<style src="./mock-quiz-exam.css" scoped></style>
