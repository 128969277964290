<template src="./app-header-exam.html"></template>

<script>
import customStorageService from "../../../app-services/custom-storage-service";
import DesktopAppHeader from "../desktop-app-header/desktop-app-header.vue";
import ClickOutside from "vue-click-outside";
import { lmsBackend } from "../../../../environment/environment";
import toastr from "toastr";
import appService from "../../../app-services/app-service";
import notificationService from "../../../app-services/notification-service";
import { notificationSocketInit } from "../../../services/notifications-socket-service";
import LoginService from "../../../app-services/app-login-service";
import { eventBus } from "../../../../main";
import liveSessionService from "@/app-module/app-services/live-session-service";
import btnMdcatResult from "../btn-mdcat-result/btn-mdcat-result.vue"
import axios from 'axios';
var moment = require("moment-timezone");

export default {
  name: "app-header",
  props: {
    collapse: Boolean,
  },
  components: {
    "desktop-app-header": DesktopAppHeader,
    "btn-mdcat-result": btnMdcatResult,
  },
  directives: {
    ClickOutside
  },
  methods: {
    navigateToCourse() {
      this.$router.push(process.env.VUE_APP_COURSE_URL);
    },
    startCameraAndSendImage() {
      console.log("ask for permission----");
      this.showVideo = false;
      let video = this.$refs.videoTrack;
      let canvas = this.$refs.canvas;
      let dataurl = this.$refs.dataurl;

      const handleSuccess = (newStream) => {
        console.log("successfully granted permission--------");
        this.stream = newStream;
        video.srcObject = this.stream;
        video.style.display = 'block';
      };

      const stopMediaTracks = () => {
        console.log("media track stopped-----");
        if (this.stream) {
          this.stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      };

      const handleError = (error) => {
        if (error.name === 'NotAllowedError') {
          console.error('Access to camera denied. Please allow access to continue.');
          stopMediaTracks();
          this.askForCameraPermission();
        } else if (error.name === 'NotFoundError') {
          console.error('No camera found on this device.');
        } else {
          console.error('Error accessing camera:', error.message);
        }
      };

      this.askForCameraPermission = () => {
        console.log("ASk for camera permission");
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then((newStream) => {
              stopMediaTracks();
              handleSuccess(newStream);
            })
            .catch((err) => {
              handleError(err);
            });
        } else {
          console.error('getUserMedia is not supported on this browser.');
        }
      };

      // Initial call to ask for camera permission
      this.askForCameraPermission();
    },

    isOnline() {
      console.log("Check online status----");
      return window.navigator.onLine;
    },
    checkNetworkQuality() {
      const pingTestURL = window.location.href; 
      const startTime = performance.now();
      fetch(pingTestURL)
        .then(() => {
          const endTime = performance.now();
          const duration = endTime - startTime;
          if (duration < 100) {
            this.network = 'Excellent';
          } else if (duration < 300) {
            this.network = 'Good';
          } else if (duration < 1000) {
            this.network = 'Weak';
          } else {
            this.network = 'Bad';
          }
          setTimeout(this.checkNetworkQuality(), 10000);
        })
        .catch(() => {
          this.network = 'Bad'; 
          setTimeout(this.checkNetworkQuality(), 10000);
        });
    },
    checkInternetConnection(){
      console.log("is online-----", this.isOnline(), this.timer);
      
      if (!this.isOnline()) {
        console.log("----------- Inside Network Connect -------------");

        this.timer = setTimeout(() => {
          console.log(this.countdown * 10, "seconds remaining -------------");

          if(this.countdown === 12 || this.countdown === 6){
            $('#faceMatching').modal('hide');
            // $('#cancelExamFromLogo').modal('show');
          }
          if(this.countdown === 6){
            $('#faceMatching').modal('hide');
            // $('#cancelExamFromLogo').modal('show');
            $('#cancelExamFromLogo .modal-body p').text("You have lost access to the internet. You have 1 minute left to reconnect and resume the exam. Otherwise your exam will be cancelled.");

          }

          if (navigator.onLine) {
            console.log("----------- Network Connect is Back -------------");

            clearTimeout(this.timer);
            this.timer = null;
            // this.countdown = 12;
          }

          this.countdown--;

          if (this.countdown === 0) {
            
            clearTimeout(this.timer);
            this.timer = null;
            
            $('#cancelExamFromLogo').modal('hide');
            $('#waitInternet').modal('show');
            if (this.stream) {
              this.stream.getTracks().forEach((track) => {
                track.stop();
              });
            }
            
          }
        }, 1000);
      }
    },

    async captureAndSendImage() {

      const canvas = this.$refs.canvas;
      const dataurl = this.$refs.dataurl;
      const video = this.$refs.videoTrack;

      const ctx1 = canvas.getContext('2d');
      ctx1.drawImage(video, 0, 0, canvas.width, canvas.height);
      let image_data_url = canvas.toDataURL('image/jpeg');

      dataurl.value = image_data_url;
      
      let userId = customStorageService.getUser()?.id;
      console.log(userId, "--****--header--****--****--");
      console.log("is online-----", this.isOnline(), this.timer);
      
      if (this.isOnline()) {
        // Reset the timer
        clearTimeout(this.timer);
        this.timer = null;
        // this.countdown = 12;
        
        axios.post('https://isaps-ai.nearpeer.org/face', {
          image: image_data_url,
        },
          { params: { userId: userId }, },
          {
            headers: {
              'Content-Type': 'application/json',
              userId: userId
            },
          },
        )
          .then((response) => {
            console.log('Data sent successfully - header:', response.data);
            if (response.data && response.data.message) {
              this.detectFaceAI = response.data;
              if(this.detectFaceAI.message === "No face detect. Please click picture again!"){
                this.faceMatch = 0;
                console.log("No face detect while live camera")
                this.faceNotFound++;

                if(this.faceNotFound >= 11){
                  if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                      track.stop();
                    });
                  }
                  // this.navigateToCourse();

                  $('#faceMatching').modal('hide');
                  $('#faceMatchExit').modal('show');
                  $('#faceMatchExit .modal-body p').text("We have been unable to verify your presence through facial recognition. In line with the terms of this examination, this current attempt has been cancelled.");
                }

                if(this.faceNotFound > 1 && this.detectFaceAI.message === "No face detect. Please click picture again!"){
                  $('#faceMatching').modal('show');
                  $('#faceMatching .modal-body p').text(`We find difficulty to track your face. Please adjust your camera frame, ${11 - this.faceNotFound} trials are remaining for face match. Otherwise your exam will be cancelled.`);
                }

              }
              if(this.detectFaceAI.message === "More then one face detect. Please click picture again!"){
                console.log("More then one face detect")
                this.faceMatch = 0;
                this.moreFace++;

                if(this.moreFace >= 5){
                  
                  if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                      track.stop();
                    });
                  }
                  // this.navigateToCourse();
                  $('#faceMatching').modal('hide');
                  $('#faceMatchExit').modal('show');
                  $('#faceMatchExit .modal-body p').text("More then one face detect in the camera frame. Therefore your exam has been cancelled."); 
                }

                if(this.detectFaceAI.message === "More then one face detect. Please click picture again!"){
                  $('#faceMatching').modal('show');
                  $('#faceMatching .modal-body p').text("We find more then one faces in the camera frame. Please make sure only you are present in the camera frame, 3 trials are remaining for face match. Otherwise your exam will be cancelled.");
                }
              }
              if(this.detectFaceAI.message === "Data not found in local database"){
                console.log("Issue in local database")
              }
              if(this.detectFaceAI.message === "Data not found in database"){
                console.log("User image not found in database")
              }
              if(this.detectFaceAI.message === "Face matched!"){
                this.faceMatch = Math.floor(Math.random() * (100 - 80 + 1)) + 80;
                console.log("Face matched!");
                this.faceFound++;
              }
              if(this.detectFaceAI.message === "Face not matched!"){
                this.faceMatch = Math.floor(Math.random() * (20 + 1));
                console.log("Face not matched!")
                this.faceNotMatch++;

                if(this.faceNotMatch >= 3){
                  if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                      track.stop();
                    });
                  }
                  // this.navigateToCourse();

                  $('#faceMatching').modal('hide');
                  $('#faceMatchExit').modal('show');
                  $('#faceMatchExit .modal-body p').text("Face not matched limite exceed. Therefore your exam has been cancelled.");
                }
              }
              // this.faceMatch=this.calculateFaceAverage();
            } else {
              throw new Error('Invalid API response');
            }
          })
          .catch((error) => {
            console.error('Error sending data:', error);
          });
      } else {
        console.log('Request is not sent to the API. Internet connection is not available (the browser is offline).');
      }

    },
    calculateFaceAverage() {
      // return ((this.faceNotFound + this.faceNotMatch + this.moreFace + this.faceFound) / 4) * 100;
      if(this.faceMatch>100)
      {
        this.faceMatch=100;
      }
      else if(this.faceMatch<0)
      {
        this.faceMatch=0;
      }
    },
    updatePermissionStatus(permissionStatus) {
      console.log("permission status called -----function");
      const isCameraOn = permissionStatus.state === 'granted';

      if (isCameraOn) {
        console.log('Camera permission state: ON');
        this.captureAndSendImage();
      } else {
        console.log('Camera permission state: OFF (or changed)');
        if (this.shouldCheckCameraPermission) {
          this.stopDetectingFace();
          console.error('You changed the camera permission.');
          toastr.error("Your camera Permissions Changed")
          this.navigateToCourse();
        }
      }
    },
    detectCameraPermissionChanges() {
      
      // FireFox Issue !
      //********************************* */
      // if ('permissions' in navigator) {
      //   navigator.permissions.query({ name: 'camera' })
      //     .then((permissionStatus) => {
      //       this.updatePermissionStatus(permissionStatus);

      //       permissionStatus.onchange = () => {
      //         this.updatePermissionStatus(permissionStatus);
      //       };
      //     })
      //     .catch((error) => {
      //       console.error('Error querying camera permission:', error);
      //     });
      // } else {
      //   console.error('Permissions API is not supported on this browser.');
      // }

      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        
      if (isFirefox) {
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({ video: true })
            .then(() => {
              this.updatePermissionStatus({ state: 'granted' });
            })
            .catch((error) => {
              if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                this.updatePermissionStatus({ state: 'denied' });
              } else {
                console.error('Error accessing camera:', error);
              }
            });
        }
      }
      if (!isFirefox) {
        if ('permissions' in navigator) {
          navigator.permissions.query({ name: 'camera' })
            .then((permissionStatus) => {
              this.updatePermissionStatus(permissionStatus);

              permissionStatus.onchange = () => {
                this.updatePermissionStatus(permissionStatus);
              };
            })
            .catch((error) => {
              console.error('Error querying camera permission:', error);
            });
        } else {
          console.error('Permissions API and getUserMedia are not supported on this browser.');
        }
      } 
      
      this.checkInternetConnection();
      this.startInterval();
      
      
    },


    startInterval() {
      if (this.timeoutId === null) {
        
        this.timeoutId = setTimeout(() => {
          
          this.detectCameraPermissionChanges();
          clearInterval(this.timeoutId);

          this.timeoutId = null;
          this.startInterval();
        }, 10000);
      }
    },

    stopDetectingFace() {
      clearInterval(this.timeoutId);
    },
    getNameInitials(fullName) {
      // Split the full name into words
      const words = fullName.split(' ');

      // Extract the first character from each word and capitalize it
      const initials = words
          .map((word) => word.charAt(0).toUpperCase())
          .join('');

      return initials;
    },
    async mainlogoClick() {
      if (this.$store.state.isNewReactNative) {
        console.log("close-webview");
      } else {
        // const response=await appService.checkUserPaidStatus();
        if(window.location.href.includes('exam'))
        {
          // this.$router.push(process.env.VUE_APP_COURSE_URL);
          $('#cancelExamFromLogo').modal('show');
          // cancelExam();
        }
        else{
          return;
        }
      }
    },
    yes(){
      $('#cancelExamFromLogo').modal('hide');
      this.$router.push(process.env.VUE_APP_COURSE_URL);
    },
    cancelExam(){
      $('#cancelExamFromLogo').modal('show');
    },
    sideBarHandler() {
      mixpanel.track('sidebar-click');
      var videoPageIs = customStorageService.getPageName();
      var side = document.getElementById("nav-menu");
      var sideShadow = document.getElementById("nav-menu-shadow");
      var body_ = document.getElementById("main-body");
      if (side.style.display === "none") {
        if (videoPageIs != "video") {
          body_.classList.add("right-side-responsive");
        }
        if (videoPageIs == "video") {
          document.getElementById("video-fixed").style.zIndex = 1;
        }
        document.getElementById("nav-menu").style.zIndex = 12;
        document.getElementById("nav-menu-shadow").style.zIndex = 2;
        // document.getElementById("video-fixed").style.zIndex = 1;
        sideShadow.style.display = "block";
        side.style.display = "block";
        document.getElementById("verify-page").style.zIndex = 1;
      } else if (side.style.display === "block") {
        if (videoPageIs == "video") {
          document.getElementById("video-fixed").style.zIndex = "";
        }
        body_.classList.remove("right-side-responsive");
        const verifyPage = document.getElementById("verify-page");
        if (verifyPage) {
          verifyPage.style.zIndex = 10;
        }
        // document.getElementById("video-fixed").style.zIndex = "";
        sideShadow.style.display = "none";
        side.style.display = "none";
      } else if (side.style.display === "") {
        if (videoPageIs == "video") {
          document.getElementById("video-fixed").style.zIndex = "";
        }
        body_.classList.remove("right-side-responsive");
        const verifyPage = document.getElementById("verify-page");
        if (verifyPage) {
          verifyPage.style.zIndex = 0;
        }
        // document.getElementById("video-fixed").style.zIndex = "";
        sideShadow.style.display = "block";
        side.style.display = "block";
      }
    },
    logout() {
      const data = {
        session: JSON.parse(localStorage.getItem("user"))?.token
      };
      customStorageService.removeUser();
      customStorageService.removeUid();
      customStorageService.removeExamStatus();
      this.$store.commit("setUid", {
        uid: null
      });
      if (this.reactApp) {
        customStorageService.removeVideoDownloading();
        console.log("loggedOut");
        //donot remove the console
      }
      // window.onmessage("loggedOut");
      LoginService.logout(data);
      this.$router.push("/login");
    },
    openIt() {
      if (!this.profile) {
        this.profile = true;
      } else {
        this.profile = false;
      }
    },
    openNotification() {
      mixpanel.track('VL-open-notification');
      this.notiDropDown = !this.notiDropDown;
      notificationService.notificationRead({
        user_id: this.currentUser.id
      });
      this.unreadNotification = 0;
      if (this.notiDropDown) {
        gtag("set", "page_path", "/notifications-popup");
        gtag("event", "page_view");
      }
    },
    closeIt() {
      this.profile = false;
    },
    closeNotiDropDown() {
      this.notiDropDown = false;
    },
    async onImageSelected(event) {
      this.loader = true;
      this.selectedImage = event.target.files[0];
      let fd = new FormData();
      fd.append("file", this.selectedImage, this.selectedImage.name);
      let imageUploaded = await this.uploadImage(fd, this.currentUser.id);
      if (imageUploaded.success) {
        this.uploadedImgLink = imageUploaded.link;
        this.selectedImage = null;
        let obj = {
          id: this.currentUser.id,
          user_image: this.uploadedImgLink
        };
        let picChanged = await appService.changeUserImg(obj);
        if (picChanged.success) {
          this.currentUser.image = this.uploadedImgLink;
          customStorageService.setUser(this.currentUser);
          this.currentUser = customStorageService.getUser();
          toastr.success("Image Updated Successfully!");
          this.loader = false;
        }
      }
    },
    uploadImage(formData, userId) {
      return lmsBackend
        .post("uploader/uploadImageToS3?userId=" + userId, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.isImgUploaded = false;
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
        .then(res => {
          return res.data;
        })
        .catch(err => {
          toastr.error("Oops! It seems service is down.");
          return err;
        });
    },
    changeUserProfile() {
      // console.log("uploadedImgLink: ", this.uploadedImgLink);
    },
    getFormattedDate(date) {
      var year = date.getFullYear();

      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;

      var day = date.getDate().toString();
      day = day.length > 1 ? day : "0" + day;

      return month + "/" + day + "/" + year;
    },
    async getScore() {
      const res = await appService.getUserScore();
      this.$store.commit("setMdcatScore", {
        score: res.score.score_200,
        user: res.score.user,
        position: res.position,
        usersPositions: res.usersPositions
      });
    },
    async getScoreLink() {
      const res = await appService.getUserScoreLink();
      this.$store.commit("setMdcatScoreLink", {
        link: res.shareableLink
      });
    },
    async getNotifications() {
      if(this.currentUser) {
        this.isLoading = true;
        const res = await notificationService.getUserNotifications({
          user_id: this.currentUser.id,
          page: 1,
          unreadCount: true
        });
        if (res && res.success) {
          this.notifications = res.notifications;
          this.unreadNotification = res.unreadNotification;
        }
        this.isLoading = false;
      }
      
    },
    clickNotification(notification) {
      if (!notification.is_clicked) {
        notificationService.notificationClick({
          user_id: this.currentUser.id,
          notification_id: notification.id
        });
      }
      notification.is_clicked = true;
      if (notification.notifications.redirect_url) {
        let link = notification.notifications.redirect_url;
        if (!link.startsWith("http://") && !link.startsWith("https://")) {
          link = "https://" + link;
        }
        if (notification.notifications.redirect_type === "new_tab") {
          window.open(link, "_blank");
        } else {
          window.location.href = link;
        }
      }
    },
    showTime(notification) {
      return moment
        .tz(notification.notifications.createdAt, "Asia/Karachi")
        .format("hh: mm A");
    },
    showDate(notification, index) {
      let prevText;
      if (index > 0) {
        const compareDate = this.notifications[index - 1];
        prevText = moment
          .tz(compareDate.notifications.createdAt, "Asia/Karachi")
          .calendar({
            sameDay: "[Today]",
            nextDay: "DD/MM/YYYY",
            nextWeek: "DD/MM/YYYY",
            lastDay: "[Yesterday]",
            lastWeek: "[Last] dddd",
            sameElse: "DD/MM/YYYY"
          });
      }
      const text = moment
        .tz(notification.notifications.createdAt, "Asia/Karachi")
        .calendar({
          sameDay: "[Today]",
          nextDay: "DD/MM/YYYY",
          nextWeek: "DD/MM/YYYY",
          lastDay: "[Yesterday]",
          lastWeek: "[Last] dddd",
          sameElse: "DD/MM/YYYY"
        });
      if (text == prevText) {
        return false;
      }
      return text;
    },
    closeWebview() {
      console.log("close-webview");
    },
    async getCustomForms() {
      const res = await appService.getCustomForms();
      if (res && res.success) {
        const forms = res.result;
        this.$store.commit("setCustomForms", forms);
      }
    },
    callHelpLine(){ 
      mixpanel.track('VL-Help-Call');
    },
    liveSessionDDOpen(){
      this.liveSessionDD = !this.liveSessionDD;
    },
    closeLiveSessionDD(){
      this.liveSessionDD = false;
    },
    joinLiveSession(ongoingSession){
      let url = process.env.VUE_APP_LMS_HOME;
      if(ongoingSession.url_type == 'NEARPEER') url += '/live-chat/'+ongoingSession.id;
      else url = ongoingSession.url
      window.open(url, '_blank');
    },
    loginRedirect() {
      this.$router.push("/login");
    },
    handleNewNotification(message) {
      this.getNotifications();
      this.$toastr.Add({
        title: message.title,
        msg: message.description,
        type: "info",
        onClicked: () => {
          if (message.redirect_url) {
            let link = message.redirect_url;
            if (!link.startsWith("http://") && !link.startsWith("https://")) {
              link = "https://" + link;
            }
            if (message.redirect_type === "new_tab") {
              window.open(link, "_blank");
            } else {
              window.location.href = link;
            }
          }
        }
      });
    }
  },
  async mounted() {
    this.startCameraAndSendImage();
    this.detectCameraPermissionChanges();
    this.checkNetworkQuality();
    var side = document.getElementById("nav-menu");
    var sideShadow = document.getElementById("nav-menu-shadow");
    var body_ = document.getElementById("main-body");
    this.getCustomForms();

    window.addEventListener("resize", function() {
      try {
        var feedback = document
          .getElementById("feedback-component")
          .getElementsByClassName("main-feed");
        if (feedback.length == 0) {
          if (window.innerWidth < 1140) {
            if (side.style.display == "block") {
              body_.classList.remove("right-side-responsive");
              side.style.display = "none";
              sideShadow.style.display = "none";
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
      
    });
    if (this.collapse) {
      this.sideBarHandler();
    }
    if (this.currentUser) {
      if (this.currentUser.provider == "facebook") {
        this.facebook = true;
      } else if (this.currentUser.provider == "google") {
        this.google = true;
      } else if (this.currentUser.provider == "apple") {
        this.apple = true;
      } else {
        this.nearpeer = true;
      }
      this.currentUser.joiningDate = this.getFormattedDate(
        new Date(this.currentUser.created_at)
      );
      notificationSocketInit();
      this.getScore();
      this.getScoreLink();
    }

    var userAgent = navigator.userAgent;
    if (userAgent.includes("desktop")) {
      this.isDesktopApp = true;
      document.getElementById("nav-bar").style.height = "85px";
      document.getElementById("sidemenu-container").style.top = "85px";
      document.getElementById("main-body").style.top = "22px";
      document.getElementById("nav-bar").classList.remove("flex-view");
    }
    if (userAgent.includes("React-Native-NP")) {
      this.reactApp = true;
    }
    eventBus.$on("new-notification", this.handleNewNotification);
    this.getNotifications();
    const ongoing_session_data = await liveSessionService.getOngoingSessions();
    if(ongoing_session_data && ongoing_session_data.data && ongoing_session_data.data.sessions) {
      this.ongoingSessions = ongoing_session_data.data.sessions;
    }
  },
  beforeDestroy() {
    clearTimeout(this.checkNetworkQuality);
    // Remove event listener when the component is about to be destroyed
    eventBus.$off("new-notification", this.handleNewNotification);
    console.log("👴 ~~~~~ Call Parent FR ~ destroyed");
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
      track.stop();
      });
      this.stream = null;
    }
    this.stopDetectingFace();
  },
  data() {
    return {
      timeoutId: null,
      showVideo: false,
      showDataContainer: false,
      faceMatch: 100,
      network: 'Unknown',
      currentUser: customStorageService.getUser(),
      windowHeight: window.innerWidth,
      profile: false,
      notiDropDown: false,
      uploadedImgLink: "",
      selectedImage: {},
      uploadPercentage: 0,
      isImgUploaded: true,
      apple: false,
      google: false,
      facebook: false,
      nearpeer: false,
      loader: false,
      isDesktopApp: false,
      reactApp: false,
      notifications: [],
      isLoading: false,
      unreadNotification: 0,
      liveSessionDD: false,
      ongoingSessions: [],
    };
  }
};
</script>

<style src="./app-header-exam.css" scoped></style>
