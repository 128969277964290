<template src="./isap-steps.html"></template>

<script>
import customStorageService from "../../../app-services/custom-storage-service";

export default {
  name: "app-isap-steps",
  components: {
  },
  data() {
    return {
      modules: [],
      copyModules:[],
    };
  },
  props: {
    module: {
      type: Array
    }
  },
  methods: {},
  mounted() {
    const data = customStorageService.getUserQuiz();
    this.modules = data.modules;
    this.copyModules = [
      { name: "Start", is_completed: true,progress:0 },
      ...data.modules,
      { name: "Complete", is_completed: false,progress:100 },
    ];
    this.activeModule = this.modules.filter(module => module.is_active);
  }
};
</script>

<style src="./isap-steps.css" scoped></style>
