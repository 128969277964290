import { uniqueId } from "lodash";
import { mainBackend, lmsBackend } from "../../environment/environment";

const width = window.screen.width * window.devicePixelRatio;
const height = window.screen.height * window.devicePixelRatio;

const dimensions = {
  width,
  height,
  dimensions: height + "x" + width
};

class CompleteProfileService {
  completeProfile(profileData) {
    profileData.origin_url = process.env.VUE_APP_LMS_HOME;
    profileData.origin = "LMS";
    profileData.dimensions = dimensions;
    return lmsBackend
      .post("/users/profileComplete", profileData)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  checkStatus() {
    return lmsBackend
      .get("/users/checkStatus")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  getUserStep() {
    return lmsBackend
    .get("users/getUserData")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  getLevels() {
    return lmsBackend
      .get("/users/get-levels")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  getBoards() {
    return lmsBackend
      .get("/users/get-boards")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  getSubjects() {
    return lmsBackend
      .get("/users/get-subjects")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  getAcademicQualifications() {
    return lmsBackend
      .get("/users/get-academic-qualifications")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  getProfessionalQualifications() {
    return lmsBackend
      .get("/users/get-professional-qualifications")
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

}

export default new CompleteProfileService();
