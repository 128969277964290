<template src="./exam.html"></template>

<script>
import AppVerification from "./../shared-module/components/app-verification/app-verification";
import appHeaderExam from "./../shared-module/components/app-header-exam/app-header-exam";
import axios from 'axios';
import CustomStorageService from "./../app-services/custom-storage-service";
import toastr from "toastr";

export default {
  name: "app-exam",
  props: {
    msg: String
  },
  components: {
    "app-header-exam": appHeaderExam,
    "app-verification": AppVerification,
  },
  methods: {
    navigateToCourse() {
      this.$router.push(process.env.VUE_APP_COURSE_URL);
    },
    startCameraAndSendImage() {
      this.showVideo = false;
      let video = this.$refs.videoTrack;
      let canvas = this.$refs.canvas;
      let dataurl = this.$refs.dataurl;

      const handleSuccess = (newStream) => {
        this.stream = newStream;
        video.srcObject = this.stream;
        video.style.display = 'block';
      };

      const stopMediaTracks = () => {
        if (this.stream) {
          this.stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      };

      const handleError = (error) => {
        if (error.name === 'NotAllowedError') {
          console.error('Access to camera denied. Please allow access to continue.');
          stopMediaTracks();
          this.askForCameraPermission();
        } else if (error.name === 'NotFoundError') {
          console.error('No camera found on this device.');
        } else {
          console.error('Error accessing camera:', error.message);
        }
      };

      this.askForCameraPermission = () => {
        console.log("ASk for camera permission");
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then((newStream) => {
              stopMediaTracks();
              handleSuccess(newStream);
            })
            .catch((err) => {
              handleError(err);
            });
        } else {
          console.error('getUserMedia is not supported on this browser.');
        }
      };

      // Initial call to ask for camera permission
      this.askForCameraPermission();
    },

    isOnline() {
      console.log("Check online status----");
      return window.navigator.onLine;
    },
    checkNetworkQuality() {
      const pingTestURL = window.location.href; 
      const startTime = performance.now();
      fetch(pingTestURL)
        .then(() => {
          const endTime = performance.now();
          const duration = endTime - startTime;
          if (duration < 100) {
            this.networkQuality = 'Excellent';
          } else if (duration < 300) {
            this.networkQuality = 'Good';
          } else if (duration < 1000) {
            this.networkQuality = 'Weak';
          } else {
            this.networkQuality = 'Bad';
          }
          setTimeout(this.checkNetworkQuality(), 10000);
        })
        .catch(() => {
          this.networkQuality = 'Bad'; 
          setTimeout(this.checkNetworkQuality(), 10000);
        });
    },
    checkInternetConnection(){
      console.log("is online-----", this.isOnline(), this.timer);
      
      if (!this.isOnline()) {
        console.log("----------- Inside Network Connect -------------");

        this.timer = setTimeout(() => {
          console.log(this.countdown * 10, "seconds remaining -------------");

          if(this.countdown === 12 || this.countdown === 6){
            $('#faceMatching').modal('hide');
            $('#waitInternet').modal('hide');
            $('#faceMatchExit').modal('hide');
            if(!this.examCancelled) $('#disconnectInternet').modal('show');
          }
          if(this.countdown === 6){
            $('#faceMatching').modal('hide');
            $('#waitInternet').modal('hide');
            $('#faceMatchExit').modal('hide');
            if(!this.examCancelled) $('#disconnectInternet').modal('show');
            $('#disconnectInternet .modal-body p').text("You have lost access to the internet. You have 1 minute left to reconnect and resume the exam. Otherwise your exam will be cancelled.");

          }

          if (navigator.onLine) {
            console.log("----------- Network Connect is Back -------------");

            clearTimeout(this.timer);
            this.timer = null;
            // this.countdown = 12;
          }

          this.countdown--;

          if (this.countdown === 0) {
            
            clearTimeout(this.timer);
            this.timer = null;


            $('#faceMatching').modal('hide');
            $('#faceMatchExit').modal('hide');
            $('#disconnectInternet').modal('hide');
            if(!this.examCancelled) $('#waitInternet').modal('show', {backdrop: 'static', keyboard: false});
            this.examCancelled = true;
            if (this.stream) {
              this.stream.getTracks().forEach((track) => {
                track.stop();
              });
            }
            
          }
        }, 1000);
      }
    },

    async captureAndSendImage() {

      const canvas = this.$refs.canvas;
      const dataurl = this.$refs.dataurl;
      const video = this.$refs.videoTrack;

      const ctx1 = canvas.getContext('2d');
      ctx1.drawImage(video, 0, 0, canvas.width, canvas.height);
      let image_data_url = canvas.toDataURL('image/jpeg');

      dataurl.value = image_data_url;
      
      let userId = CustomStorageService.getUser()?.id;
      
      if (this.isOnline()) {
        // Reset the timer
        clearTimeout(this.timer);
        this.timer = null;
        // this.countdown = 12;
        
        axios.post('https://isaps-ai.nearpeer.org/face', {
          image: image_data_url,
        },
          { params: { userId: userId }, },
          {
            headers: {
              'Content-Type': 'application/json',
              userId: userId
            },
          },
        )
          .then((response) => {
            console.log('Data sent successfully:', response.data);
            if (response.data && response.data.message) {
              this.detectFaceAI = response.data;
              if(this.detectFaceAI.message === "No face detect. Please click picture again!"){
                this.facePercentage = 0;
                console.log("No face detect while live camera")
                this.faceNotFound++;

                if(this.faceNotFound >= 11){
                  if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                      track.stop();
                    });
                  }
                  // this.navigateToCourse();

                  $('#faceMatching').modal('hide');
                  $('#disconnectInternet').modal('hide');
                  $('#waitInternet').modal('hide');
                  if(!this.examCancelled) $('#faceMatchExit').modal('show');
                  this.examCancelled = true;
                  $('#faceMatchExit .modal-body p').text("We have been unable to verify your presence through facial recognition. In line with the terms of this examination, this current attempt has been cancelled.");
                }
                else if(this.faceNotFound > 1 && this.detectFaceAI.message === "No face detect. Please click picture again!"){
                  if(!this.examCancelled) $('#faceMatching').modal('show');
                  $('#faceMatching .modal-body p').text(`We find difficulty to track your face. Please adjust your camera frame, ${11 - this.faceNotFound} trials are remaining for face match. Otherwise your exam will be cancelled.`);
                }

              }
              if(this.detectFaceAI.message === "More then one face detect. Please click picture again!"){
                console.log("More then one face detect")
                this.facePercentage = 0;
                this.moreFace++;

                if(this.moreFace >= 5){
                  
                  if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                      track.stop();
                    });
                  }
                  // this.navigateToCourse();
                  $('#faceMatching').modal('hide');
                  $('#disconnectInternet').modal('hide');
                  $('#waitInternet').modal('hide');
                  if(!this.examCancelled) $('#faceMatchExit').modal('show');
                  this.examCancelled = true;
                  $('#faceMatchExit .modal-body p').text("More then one face detect in the camera frame. Therefore your exam has been cancelled."); 
                }
                else if(this.detectFaceAI.message === "More then one face detect. Please click picture again!"){
                  if(!this.examCancelled) $('#faceMatching').modal('show');
                  $('#faceMatching .modal-body p').text("We find more then one faces in the camera frame. Please make sure only you are present in the camera frame, 3 trials are remaining for face match. Otherwise your exam will be cancelled.");
                }
              }
              if(this.detectFaceAI.message === "Data not found in local database"){
                console.log("Issue in local database")
              }
              if(this.detectFaceAI.message === "Data not found in database"){
                console.log("User image not found in database")
              }
              if(this.detectFaceAI.message === "Face matched!"){
                this.facePercentage = Math.floor(Math.random() * (100 - 80 + 1)) + 80;
                console.log("Face matched!");
                this.faceFound++;
              }
              if(this.detectFaceAI.message === "Face not matched!"){
                this.facePercentage = Math.floor(Math.random() * (20 + 1));
                console.log("Face not matched!")
                this.faceNotMatch++;

                if(this.faceNotMatch >= 3){
                  if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                      track.stop();
                    });
                  }
                  // this.navigateToCourse();

                  $('#faceMatching').modal('hide');
                  $('#disconnectInternet').modal('hide');
                  $('#waitInternet').modal('hide');
                  if(!this.examCancelled) $('#faceMatchExit').modal('show');
                  this.examCancelled = true;
                  $('#faceMatchExit .modal-body p').text("We have been unable to verify your presence through facial recognition. In line with the terms of this examination, this current attempt has been cancelled.");
                }
              }
              // this.facePercentage=this.calculateFaceAverage();
            } else {
              throw new Error('Invalid API response');
            }
          })
          .catch((error) => {
            console.error('Error sending data:', error);
          });
      } else {
        console.log('Request is not sent to the API. Internet connection is not available (the browser is offline).');
      }

    },
    calculateFaceAverage() {
      // return ((this.faceNotFound + this.faceNotMatch + this.moreFace + this.faceFound) / 4) * 100;
      if(this.facePercentage>100)
      {
        this.facePercentage=100;
      }
      else if(this.facePercentage<0)
      {
        this.facePercentage=0;
      }
    },
    updatePermissionStatus(permissionStatus) {
      const isCameraOn = permissionStatus.state === 'granted';

      if (isCameraOn) {
        this.captureAndSendImage();
      } else {
        if (this.shouldCheckCameraPermission) {
          this.stopDetectingFace();
          toastr.error("Your camera Permissions Changed")
          this.navigateToCourse();
        }
      }
    },
    detectCameraPermissionChanges() {
      
      // FireFox Issue !
      //********************************* */
      // if ('permissions' in navigator) {
      //   navigator.permissions.query({ name: 'camera' })
      //     .then((permissionStatus) => {
      //       this.updatePermissionStatus(permissionStatus);

      //       permissionStatus.onchange = () => {
      //         this.updatePermissionStatus(permissionStatus);
      //       };
      //     })
      //     .catch((error) => {
      //       console.error('Error querying camera permission:', error);
      //     });
      // } else {
      //   console.error('Permissions API is not supported on this browser.');
      // }

      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        
      if (isFirefox) {
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({ video: true })
            .then(() => {
              this.updatePermissionStatus({ state: 'granted' });
            })
            .catch((error) => {
              if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                this.updatePermissionStatus({ state: 'denied' });
              } else {
                console.error('Error accessing camera:', error);
              }
            });
        }
      }
      if (!isFirefox) {
        if ('permissions' in navigator) {
          navigator.permissions.query({ name: 'camera' })
            .then((permissionStatus) => {
              this.updatePermissionStatus(permissionStatus);

              permissionStatus.onchange = () => {
                this.updatePermissionStatus(permissionStatus);
              };
            })
            .catch((error) => {
              console.error('Error querying camera permission:', error);
            });
        } else {
          console.error('Permissions API and getUserMedia are not supported on this browser.');
        }
      } 
      
      this.checkInternetConnection();
      this.startInterval();
      
      
    },


    startInterval() {
      
      if (this.timeoutId === null) {
        
        this.timeoutId = setTimeout(() => {
          
          this.detectCameraPermissionChanges();
          clearInterval(this.timeoutId);

          this.timeoutId = null;
          this.startInterval();
        }, 10000);
      }
    },

    stopDetectingFace() {
      clearInterval(this.timeoutId);
    },
    
  },
  beforeCreate() {
    console.log("👴 ~~~~~ Call Parent FR ~ Before instance is created");
  },
  created() {
    console.log(" 👴 ~~~~~ Call Parent FR ~ created");
    
  },
  beforeDestroy() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
      track.stop();
      });
    this.stream = null;
    }
    this.stopDetectingFace();
  },
  mounted() {
    this.startCameraAndSendImage();
    this.detectCameraPermissionChanges();
    // this.checkNetworkQuality();
  },
  afterRouteEnter(to, from, next) {
    window.scroll(0, 0);
  },
  data() {
    return {
      showVideo: false,
      showDataContainer: false,
      timeoutId: null,
      shouldCheckCameraPermission: true,
      detectFaceAI: null,
      faceNotMatch: 0,
      faceNotFound: 0,
      moreFace: 0,
      stream: null,
      callOnce: 1,
      checkConnection: true,
      timer: null,
      countdown: 12,
      networkQuality: 'Unknown',
      faceFound:0,
      facePercentage:100,
      examCancelled:false,
    };
  }
};
</script>


<style scoped lang="scss"></style>
