import { mainBackend, lmsBackend, isapsLocal } from "./../../environment/environment";

import axios from 'axios';

const width = window.screen.width * window.devicePixelRatio;
const height = window.screen.height * window.devicePixelRatio;

const dimensions = {
  width,
  height,
  dimensions: height + "x" + width
};

class FaceRecognitionService {
  static baseAddress = 'https://isaps-ai.nearpeer.org/';

  static createVerifyCnicImageRoute() {
    return `${this.baseAddress}/verify-cnic-image`;
  }

  login(loginData) {
    loginData.origin_url = process.env.VUE_APP_LMS_HOME;
    loginData.origin = "LMS";
    loginData.dimensions = dimensions;
    return lmsBackend
      .post("auth/signin", loginData)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  FaceRecognition(userId, image) {
    return axios.post('https://isaps-ai.nearpeer.org/face', {
      image: image,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'userId': userId,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error('Error sending data:', error);
      throw error;
    });
  }


}

export default new FaceRecognitionService();
