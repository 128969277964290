<template src="./payment.html"></template>

<script>
import appHeader from "../shared-module/components/app-header/app-header.vue";
import paymentService from "../app-services/payment-service";
import toastr from "toastr";
import customStorageService from '../app-services/custom-storage-service';
import appService from '../app-services/app-service';
import CompleteProfileService from '../app-services/app-complete-profile-service'

export default {
  name: "practices",
  data() {
    return {
      step: 1,
      paymentType: "jazzCash",
      couponValue: "",
      price:0,
      couponPrice: null,
      couponId: null,
      refNo: null,
      loading:false,
      coupon: "",
      isApplied:false,
      intervalId:null,
      paymentSuccess:false,
      courseName:'',
      totalPrice:0,
    };
  },
  components: {
    "app-header": appHeader,
  },
  mounted() {
    this.handleScenario();
    this.getCourse();
  },
  destroyed() {
    document.body.classList.remove("remove-floating-btn");
  },
  methods: {
    selectPaymentMethod(type) {
      this.paymentType = type;
    },
    async handleScenario(){
     const response=await appService.checkUserPaidStatus();
     const res=await CompleteProfileService.checkStatus();
     if(response.isCurrentPaid)
      {
        this.$router.push(process.env.VUE_APP_COURSE_URL);
      }
    else if(!res.filled)
      {
        this.$router.push("/profile-complete");
      }
    },
    prev() {
      this.step--;
      console.log("------steps-----------", this.step);
    },
    async getCourse()
    {
      const response=await paymentService.getCourseById(486);
      if(response.success)
      {
        this.courseName=response.course.name;
        this.price=response.course.price;
        this.totalPrice=response.course.price;
      }
    },
    async validateOrder() {
      console.log('validate order called');
      const response = await paymentService.validateOrderStatus(this.refNo);
      if (response.success && response.message=='Access Granted') {
        clearInterval(this.intervalId);
        console.log('interval cleared with success');
        this.paymentSuccess=true;
        toastr.success("Course purchased Successfully");
        this.step = 3
      }
     else if(response.status==='failed')
     {
        toastr.error("Error in purchasing course");
        this.step = 3
        clearInterval(this.intervalId);
        console.log('interval cleared with failure');
     } 
     else {
       this.intervalId = setTimeout(() => {
         this.validateOrder();
         console.log('interval set and excuted');
         // if (newWindow.closed) {
         //   clearInterval(this.intervalId); // Clear interval if the new tab is closed
         // } else {
         //   this.validateOrder();
         // }
       }, 4000);
     }
    },
    updateCouponId() {
      // Update coupon_id variable with the current value of couponValue
      this.$emit("update:coupon_id", this.couponValue);
    },
    async next() {
      this.loading=true;
      if (this.step === 1) {
        if (this.couponValue !== "") {
          this.coupon = this.couponValue;
          let currentUser = JSON.parse(localStorage.getItem("user"));
          let user_id = currentUser.id;
          const response = await paymentService.applyCoupon(
            this.couponValue,
            user_id
          );
          if (response.success) {
            this.loading=false;
            this.isApplied=true;
            toastr.success("PromoCode Applied successfully");
            this.couponValue = "";
            this.couponPrice = response.coupon.price;
            this.couponId = response.coupon.id;
            if (response.coupon.price > this.price) {
              this.couponPrice=this.price;
              this.totalPrice = 0;
            } else {
              this.totalPrice = this.price - response.coupon.price;
            }
          } else {
            this.loading=false;
            toastr.error("Wrong promo code");
          }
        } else {
          let currentUser = JSON.parse(localStorage.getItem("user"));
          let user_id = currentUser.id;
          const data = {
            user_balance: 0,
            coupon_balance: this.couponPrice,
            coupon_id: this.couponId,
            items: [{ course_id: 486, module_id: null, bundle_id: null }],
            discount_price: this.couponPrice ? this.couponPrice : 0,
            price: this.price ? this.price : 3000,
            total: this.totalPrice,
            user_id: user_id,
            type: "course",
            socket_id: "szVdSjs5G1MlmQd8AAAB",
            origin: "https://isap-dev.nearpeer.org",
            from: "checkout",
            browserId: "e5sykek5oyg4arz8t8k6ra",
          };

          const response = await paymentService.placeOrder(data);
          if (response.succes) {
            if (response?.order[0]?.status === "paid") {
              this.loading=false;
              toastr.success("Order Completed Successfully");
              this.paymentSuccess=true;
              this.step=this.step+2;
              return;
            }
            else{
            this.loading=false;
            this.refNo = response?.order[0]?.ref_no || response?.order?.jazzData?.billreference;
            toastr.success("Order Created Successfully");
            this.step++;
            }
          } else {
            this.loading=false;
            toastr.error("Error in creating order");
          }
        }
      } else if (this.step === 2) {
        if (this.paymentType === "jazzCash") {
          this.paymentType = "JAZZCASH";
        } else if (this.paymentType === "easyPaisa") {
          this.paymentType = "EASYPAISA";
        } else {
          this.paymentType = "CARD";
        }
        const response = await paymentService.redirectPortal(
          this.refNo,
          this.paymentType
        );
        if (response.success) {
          this.loading=false;
          const newWindow = window.open(
            response.paymobData.iframe_url,
            "_blank"
          );
          this.intervalId = setTimeout(() => {
            this.validateOrder();
            console.log('interval set and excuted');
            // if (newWindow.closed) {
            //   clearInterval(this.intervalId); // Clear interval if the new tab is closed
            // } else {
            //   this.validateOrder();
            // }
          }, 4000);
        } else {
          toastr.error("Something went wrong plz try again");
        }
      }
      else if(this.step===3)
      {
        if(this.paymentSuccess){
        this.$router.push(process.env.VUE_APP_COURSE_URL);
        return;
        }else{
          this.step=2;
          return;
        }
      }
      // this.step++;

      console.log("------steps-----------", this.step);
    },
  },
  watch: {},
};
</script>

<style src="./payment.css" scoped></style>
