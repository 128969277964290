  <template src="./face-recognition-page.html"></template>

  <script>
import axios from 'axios';
import FaceRecognitionService from "./../app-services/face-recognition-service";
import CustomStorageService from "./../app-services/custom-storage-service";

export default {
  data() {
    return {
      showVideo: true,
      showDataContainer: true,
      intervalId: null,
      shouldCheckCameraPermission: true,
    };
  },
  methods: {
    startCameraAndSendImage() {
    let stream;
    let video = this.$refs.video;
    let canvas = this.$refs.canvas;
    let dataurl = this.$refs.dataurl;

    const handleSuccess = (newStream) => {
        stream = newStream;
        video.srcObject = stream;
        video.style.display = 'block';
        this.showVideo = true;
    };

    const stopMediaTracks = () => {
        if (stream) {
        stream.getTracks().forEach((track) => {
            track.stop();
        });
        }
    };

    const handleError = (error) => {
        if (error.name === 'NotAllowedError') {
        console.error('Access to camera denied. Please allow access to continue.');
        stopMediaTracks();
        this.askForCameraPermission();
        } else if (error.name === 'NotFoundError') {
        console.error('No camera found on this device.');
        } else {
        console.error('Error accessing camera:', error.message);
        }
    };

    this.askForCameraPermission = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then((newStream) => {
            stopMediaTracks();
            handleSuccess(newStream);
            })
            .catch(handleError);
        } else {
        console.error('getUserMedia is not supported on this browser.');
        }
    };

    // Initial call to ask for camera permission
    this.askForCameraPermission();
    },
    // checkUserLogin(){
    //     if(!CustomStorageService.getUserSignedInStorageData()){
    //         console.log("Please login to continue")
    //     }else{
    //         this.startCameraAndSendImage()
    //     }

    // },

    isOnline() {
      return window.navigator.onLine;
    },
    async captureAndSendImage() {
      const canvas = this.$refs.canvas;
      const dataurl = this.$refs.dataurl;
      const video = this.$refs.video;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      let image_data_url = canvas.toDataURL('image/jpeg');

      dataurl.value = image_data_url;
      this.showDataContainer = true;

      // let currentUser = JSON.parse(localStorage.getItem("user"));
      let userId = CustomStorageService.getUser()?.id;
      console.log(userId, "#########################");

      // let userId = 207478; // Replace with the actual logic to get the user ID
    //   const userId = CustomStorageService.getUserInfoStorageData().id
    //   console.log("##################", userId)

      // try {
      //   if (this.isOnline()) {
      //     const response = await FaceRecognitionService.FaceRecognition(userId, image_data_url);

      //     console.log('Data sent successfully:', response.message);
      //   } else {
      //     console.log('Request is not sent to the API. Internet connection is not available (the browser is offline).');
      //   }
      // } catch (error) {
      //   console.error('Error sending data:', error);
      // }

      if (this.isOnline()) {
        axios.post('https://isaps-ai.nearpeer.org/face', {
          image: image_data_url,
        },
        {params: {userId: userId  },},
        {headers: {
            'Content-Type': 'application/json',
            userId: userId
          },},
        )
          .then((response) => {
            console.log('Data sent successfully:', response.data);
            if (response.data && response.data.message) {
              console.log(response.data.message);
            } else {
              throw new Error('Invalid API response');
            }
          })
          .catch((error) => {
            console.error('Error sending data:', error);
          });
      } else {
        console.log('Request is not sent to the API. Internet connection is not available (the browser is offline).');
      }
    },
    updatePermissionStatus(permissionStatus) {
      const isCameraOn = permissionStatus.state === 'granted';

      if (isCameraOn) {
        console.log('Camera permission state: ON');
        this.captureAndSendImage();
      } else {
        console.log('Camera permission state: OFF (or changed)');
        if (this.shouldCheckCameraPermission) {
          this.stopDetectingFace();
          console.error('You changed the camera permission.');
          this.shouldCheckCameraPermission = false;
          // Replace with the actual route for redirecting to the login page
          this.$router.push({ name: 'Login' });
        }
      }
    },
    detectCameraPermissionChanges() {
      if ('permissions' in navigator) {
        navigator.permissions.query({ name: 'camera' })
          .then((permissionStatus) => {
            this.updatePermissionStatus(permissionStatus);

            permissionStatus.onchange = () => {
              this.updatePermissionStatus(permissionStatus);
            };
          })
          .catch((error) => {
            console.error('Error querying camera permission:', error);
          });
      } else {
        console.error('Permissions API is not supported on this browser.');
      }

      this.intervalId = setInterval(() => {
        this.detectCameraPermissionChanges();
      }, 30000);
    },
    stopDetectingFace() {
      clearInterval(this.intervalId);
    },
  },
};
</script>

<style src="./face-recognition-page.css" scoped></style>