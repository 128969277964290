import { mainBackend, lmsBackend, isapsLocal } from "./../../environment/environment";

class PaymentService {
  applyCoupon(value,userId) {
    return mainBackend
      .post("/coupons/getCouponByID?course_id=486&id="+value+"&user_id="+userId)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  placeOrder(data) {
    return mainBackend
      .post("/orders/createOrder", data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  redirectPortal(refno,paymentType) {
    return mainBackend
      .get("/orders/get-paymob-url/"+refno+"/"+paymentType)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  getCourseById(id) {
    return mainBackend
      .get("/courses/getCourseById-isap?id="+id)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

  validateOrderStatus(refno) {
    return mainBackend
      .get("/order/get-order-status?refNo="+refno)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  }

}

export default new PaymentService();
