import { render, staticRenderFns } from "./chat.html?vue&type=template&id=3459d987&scoped=true&"
import script from "./chat.vue?vue&type=script&lang=js&"
export * from "./chat.vue?vue&type=script&lang=js&"
import style0 from "./chat.css?vue&type=style&index=0&id=3459d987&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3459d987",
  null
  
)

export default component.exports