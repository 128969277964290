import AppQuiz from "./exam.vue";
import customStorageService from "../app-services/custom-storage-service";
import MockQuizModule from "./components/mock-quiz-module/mock-quiz-module";
import MockQuizExam from "./components/mock-quiz-exam/mock-quiz-exam";
import MockQuizResult from "./components/mock-quiz-results/mock-quiz-results"

function authGuard(to, from, next) {
  if (customStorageService.getUser()) {
    next();
  } else {
    next("/page-not-found");
  }
}

const examRoutes = [
  {
    path: "/exam/:type",
    component: AppQuiz,
    beforeEnter: authGuard,
    children: [
      {
        path: ":quiz_id",
        name: "mock-quiz-module",
        component: MockQuizModule
      },
      // {
      //   path: "result/:result_id",
      //   name: "mock-quiz-results",
      //   component: MockQuizResult
      // },
      {
        path: ":quiz_id/:module_id",
        name: "mock-quiz-exam",
        component: MockQuizExam
      },
      {
        path: "",
        redirect: { name: "mock-quiz-module" }
      }
    ]
  },
];

export default examRoutes;
